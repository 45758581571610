import { Navigate } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import AddTeacherPage from "../pages/Teacher/AddTeacherPage";
import EditTeacherPage from "../pages/Teacher/EditTeacherPage";
import TeacherDashboard from "../pages/Teacher/TeacherDashboard";
import UpdateAdminPage from "../pages/Admin/UpdateAdminPage";
import BranchDashboard from "../pages/Branch/BranchDashboard";
import StudentDasboard from "../pages/Student/StudentDasboard";
import UpdateStudentPage from "../pages/Student/UpdateStudentPage";
import ActiveCourseDashboard from "../pages/Course/ActiveCourseDashboard";
import CourseDetailPage from "../pages/Course/CourseDetailPage";
import CreateOnlineCoursePage from "../pages/Course/CreateOnlineCoursePage";
import CourseUpdatePage from "../pages/Course/CourseUpdatePage";
import TeacherCoursePage from "../pages/Course/TeacherCoursePage";
import ProfilePage from "../pages/Profile/ProfilePage";
import Home from "../Public/Home";
import Kurumsal from "../Public/pages/kurumsal/Kurumsal";
import Ekitap from "../Public/pages/e-kitap/EKitap";
import Egitimler from "../Public/pages/egitimler/Egitimler";
import Guncel from "../Public/pages/guncel/Guncel";
import Blog from "../Public/pages/blog/Blog";
import Iletisim from "../Public/pages/iletisim/İletisim"
import Kvk from "../Public/pages/iletisim/Kvk"
import BlogDetay from "../Public/pages/blogDetay/BlogDetay";
import GuncelDetay from "../Public/pages/GuncelDetay/GuncelDetay";
import StudentCourseDashboard from "../pages/Course/StudentCourseDashboard";
import CategoryPage from "../pages/Categories/CategoryPage";
import TeacherExamPage from "../pages/TeacherExam/TeacherExamPage";
import OfflineCoursePage from "../pages/Course/OfflineCoursePage";
import CreateOfflineCoursePage from "../pages/Course/CreateOfflineCoursePage";
import VideoPage2 from "../Public/pages/videoPage/VideoPage2";
import TeacherMessagePage from "../pages/Teacher/TeacherMessagePage";
import TeacherSurveyPage from "../pages/Teacher/TeacherSurveyPage";
import StudentSurveyPage from "../pages/Student/StudenSurveyPage/StudentSurveyPage";
import CourseVideo from "../Public/pages/course/CourseVideo";
import StudentMessagePage from "../pages/Student/StudentMessagePage/StudentMessagePage";
import StudentExamPage from "../pages/Student/StudentExam/StudentExamPage";
import ExamDashboard from "../pages/Exam/ExamDashboard/ExamDashboard";
import ExamManagement from "../pages/ExamManagement/ExamManagement";
import ExamUpdatePage from "../pages/ExamUpdate/ExamUpdatePage";
import ExamStudenResultPage from "../pages/Exam/ExamStudenResult/ExamStudenResultPage";
import CertificateStudentTab from "../Components/Student/EditStudent/CertificateStudentTab";
import TeacherOnlineMeetingPage from "../pages/TeacherOnlineMeeting/TeacherOnlineMeetingPage";
import { components } from "react-select";
import StudentOnlineMeeting from "../pages/StudentOnlineMeeting/StudentOnlineMeeting";
import VideoPage3 from "../Public/pages/VideoPage3/VideoPage3";
import VideoPlayerPage from "../Public/pages/VideoPage3/VideoPlayerPage/VideoPlayerPage";
import Register from "../pages/Authentication/Register/Register";
import SoruHavuzu from "../Components/SoruHavuzu/SoruHavuzu";
import EgitmenSoruHavuzu from "../Components/EgitmenSoruHavuzu/EgitmenSoruHavuzu";
import SoruHavuzuAdminPage from "../pages/SoruHavuzu/SoruHavuzuAdminPage";
import BlogPage from "../pages/Blog/BlogPage";
import AnnouncementDashboard from "../Components/Announcement/AnnouncementDashboard";
import AnnouncementPage from "../pages/Announcement/AnnouncementPage";
import TopicalPage from "../pages/Topical/TopicalPage";
import EKitapPage from "../pages/EKitap/EKitapPage";
import TeacherInfoPage from "../Public/pages/Teachers/TeacherInfoPage";
import CourseVideoDashboard from "../Public/pages/CourseVideo/CourseVideoDashboard";
import OnerilenKurslar from "../pages/OnerilenKurslar/RecommendedCoursePage";
import RecommendedCoursePage from "../pages/OnerilenKurslar/RecommendedCoursePage";
import CevrimiciDetay from "../pages/CevrimciDetay/CevrimiciDetay";
import VideoEgitimDetay from "../pages/VideoEgitimDetay/VideoEgitimDetay";
import Egitmenler from "../Public/pages/Egitmenler/Egitmenler";
import EgitmenPage from "../Public/pages/Egitmen/EgitmenPage";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";
import UzaktanEgitim from "../Public/pages/UzaktanEgitim/UzaktanEgitim";
import OgrencilerimPage from "../pages/Ogrencilerim/OgrencilerimPage";
import AydinlatmaMetni from "../Public/pages/AydinlatmaMetni/AydinlatmaMetni";
import AdminStatisticPage from "../pages/AdminStaticticPage/AdminStatisticPage";
import AddBranchPage from "../pages/AddBranchPage/AddBranchPage";
import EditBranchPage from "../pages/EditBranchPage/EditBranchPage";
import SiteHaritasi from "../Public/pages/SiteHaritasi/SiteHaritasi";
import SmsPage from "../pages/SmsPage/SmsPage";
import TeacherAllMessagePage from "../pages/TeacherAllMessagePage/TeacherAllMessagePage";
import MeetingPage from "../pages/MeetingPage/MeetingPage";
import CanliYayinPage from "../pages/CanliYayinPage/CanliYayinPage";
import TestPage from "../pages/TestPage/TestPage";
import Test2 from "../pages/TestPage/Test2";
import AddBlogData from "../pages/Blog/AddBlogData";
import BlogUpdatePage from "../pages/Blog/BlogUpdatePage";
import TablePageContainer from "../Components/Common/TablePageContainer";
import PuantajPage from "../pages/PuantajPage/PuantajPage";
import OgrenciDevamsızlıkPage from "../pages/OgrenciDevamsızlıkPage/OgrenciDevamsızlıkPage";


/* test */

const authProtectedRoutes = [
    {
        path: "/panel/anasayfa", component: <Dashboard />, role: ["Öğretmen", "Öğrenci", "Admin", "SüperAdmin"]
    },
    {
        path: "/panel/egitmen", component: <TeacherDashboard />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/egitmen/ekle", component: <AddTeacherPage />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/egitmen/duzenle/:id", component: <EditTeacherPage />, role: ["Admin", "SüperAdmin"]
    },
    /*  {
         path: "/panel/admin", component: <AdminDashboard />, role: ["SüperAdmin"]
     }, */
    /*  {
         path: "/panel/admin/ekle", component: <CreateAdminPage />, role: ["SüperAdmin"]
     }, */
    {
        path: "/panel/admin/duzenle/:id", component: <UpdateAdminPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/branslar", component: <BranchDashboard />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/brans/ekle", component: <AddBranchPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/brans/duzenle/:id", component: <EditBranchPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/ogrenciler", component: <StudentDasboard />, role: ["Admin", "SüperAdmin"]
    },
    /* {
        path: "/panel/ogrenci/ekle", component: <CreateStudenPage />, role: ["Admin", "SüperAdmin"]
    }, */
    {
        path: "/panel/kategori", component: <CategoryPage />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/ogrenci/duzenle/:id", component: <UpdateStudentPage />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/cevrimici", component: <ActiveCourseDashboard />, role: ["Admin", "SüperAdmin", "Öğrenci"]
    },
    {
        path: "/panel/cevrimdisi", component: <OfflineCoursePage />, role: ["Admin", "SüperAdmin", "Öğrenci"]
    },
    {
        path: "/panel/kurs/:id", component: <CourseDetailPage />, role: ["Admin", "SüperAdmin", "Öğrenci"]
    },
    {
        path: "/panel/cevrimici/ekle", component: <CreateOnlineCoursePage />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/cevrimdisi/ekle", component: <CreateOfflineCoursePage />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/kurs/duzenle/:id", component: <CourseUpdatePage />, role: ["Admin", "SüperAdmin"]
    },
    {
        path: "/panel/sorumlukurslar", component: <TeacherCoursePage />, role: ["Öğretmen"]
    },
    {
        path: "/panel/kurslarim", component: <StudentCourseDashboard />, role: ["Öğrenci"]
    },
    {
        path: "/panel/profil", component: <ProfilePage />, role: ["Öğretmen", "Öğrenci", "Admin", "SüperAdmin"]
    },
    {
        path: "/panel/egitmen/sinav", component: <TeacherExamPage />, role: ["Öğretmen"]
    },
    {
        path: "/panel/egitmen/mesaj", component: <TeacherMessagePage />, role: ["Öğretmen"]
    },
    {
        path: "/panel/egitmen/butunmesajlar", component: <TeacherAllMessagePage />, role: ["Öğretmen"]
    },
    {
        path: "/panel/ogrenci/mesaj", component: <StudentMessagePage />, role: ["Öğrenci"]
    },
    {
        path: "/panel/anket", component: <TeacherSurveyPage />, role: ["Öğretmen", "Admin", "SüperAdmin"]
    },
    {
        path: "/panel/ogrenci/anket", component: <StudentSurveyPage />, role: ["Öğrenci"]
    },
    {
        path: "/panel/sinavlarim", component: <StudentExamPage />, role: ["Öğrenci"]
    },
    {
        path: "panel/sinav/:id", component: <ExamDashboard />, role: ["Öğrenci"], template: false
    },
    {
        path: "/panel/sinavlar", component: <ExamManagement />, role: ["Öğretmen", "SüperAdmin"]
    },
    {
        path: "/panel/sinav/sonuclarim", component: <ExamStudenResultPage />, role: ["Öğrenci"]
    },
    {
        path: "/panel/sinav/duzenle/:id", component: <ExamUpdatePage />, role: ["Öğretmen", "SüperAdmin"]
    },
    {
        path: "/panel/sertifika", component: <CertificateStudentTab />, role: ["Öğrenci"]
    },
    {
        path: "/panel/canliderslerim", component: <TeacherOnlineMeetingPage />, role: ["Öğretmen", "Öğrenci"]
    },
    {
        path: "/panel/egitmen/canliders", component: <TeacherOnlineMeetingPage />, role: ["Öğretmen"]
    },
    {
        path: "/panel/ogrenci/canliders", component: <StudentOnlineMeeting />, role: ["Öğrenci"]
    },
    {
        path: "/panel/soruhavuzu", component: <SoruHavuzuAdminPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/egitmen/soruhavuzu", component: <EgitmenSoruHavuzu />, role: ["Öğretmen"]
    },
    {
        path: "/panel/blog", component: <BlogPage />, role: ["SüperAdmin"]
    },
    /* {
        path: "/panel/blog/:id", component: <BlogUpdatePage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/blog/ekle", component: <AddBlogData />, role: ["SüperAdmin"]
    }, */
    {
        path: "/panel/duyurular", component: <AnnouncementPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/guncel", component: <TopicalPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/ekitap", component: <EKitapPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/onerilenkurslar", component: <RecommendedCoursePage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/cevrimicidetay", component: <CevrimiciDetay />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/videoegitimdetay", component: <VideoEgitimDetay />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/egitmen/ogrencilerim", component: <OgrencilerimPage />, role: ["Öğretmen"]
    },
    {
        path: "/panel/istatistik", component: <AdminStatisticPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/sms/ogrenci", component: <SmsPage />, role: ["SüperAdmin"]
    },
    {
        path: "/panel/toplanti", component: <MeetingPage />, role: ["SüperAdmin", "Öğretmen"]
    },
    {
        path: "/panel/canliyayin", component: <CanliYayinPage />, role: ["SüperAdmin"]
    },
    /* {
        path: "/panel/puantaj", component: <PuantajPage />, role: ["SüperAdmin"]
    }, */
    {
        path: "/panel/devamsizlik", component: <OgrenciDevamsızlıkPage />, role: ["Öğrenci"]
    }
]
/* test */

const publicRoutes = [
    // Authentication Page
    { path: "/", component: <Home /> },
    { path: "/kurumsal", component: <Kurumsal /> },
    { path: "/ekitap", component: <Ekitap /> },
    { path: "/egitimler/:id", component: <Egitimler /> },
    { path: "/egitmenler", component: <Egitmenler /> },
    { path: "/giris", component: <Login /> },
    {
        path: "/kayit", component: <Register />
    },
    {
        path: "/sifremiunuttum", component: <ResetPassword />
    },
    { path: "/guncel", component: <Guncel /> },
    { path: "/guncel/:id", component: <GuncelDetay /> },
    { path: "/blog", component: <Blog /> },
    { path: "/blog/:id", component: <BlogDetay /> },
    { path: "/blogdetay", component: <BlogDetay /> },
    { path: "/iletisim", component: <Iletisim /> },
    { path: "/kvk", component: <Kvk /> },
    { path: "/kurs/:id", component: <CourseVideoDashboard /> },
    {
        path: "/aydinlatmametni", component: <AydinlatmaMetni />
    },
    {
        path: "/uzaktanegitim", component: <UzaktanEgitim />
    },
    {
        path: "/kurs/player/:id", component: <VideoPlayerPage />
    },
    {
        path: "/egitmen/:id", component: <EgitmenPage />
    },
    /*  {
         path: "/siteharitasi", component: <SiteHaritasi />
     }, */
    /* {
        path: "/test", component: <TestPage />,
    }, */
    /* {
        path: "/test2/:id", component: <Test2 />
    }, */

    {
        path: "*", component: <Navigate to={"/"} />, role: ["Öğretmen", "Öğrenci", "Admin", "SüperAdmin"]
    }
];

export {
    publicRoutes,
    authProtectedRoutes
}