import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { Card, Input } from 'reactstrap'
import { examGetAllForStudentApi, studentGetByExamIdApi } from '../../../api/exam'
import { formatDate } from "date-fns"
import trLocale from "date-fns/locale/tr"
import { getMinuteDifference } from '../../../utils/utils'
import { MainContainer, RefreshIcon, StyledHeaderContainer, StyledSearchInput, StyledSelect } from './studentExamStyle'
import { MdRefresh } from 'react-icons/md'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { Select, Input as AntdInput, Space, Button, Table as AntTable, Progress } from 'antd'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const StudentExamDashboard = () => {
    const navigation = useNavigate()

    const [examData, setExamData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [examTimeType, setExamTimeType] = useState("1")
    const [loading, setLoading] = useState(false)
    const TimeControl = (startTime, endTime) => {
        const currentTime = new Date();
        const startTimeControl = new Date(startTime);
        const endTimeControl = new Date(endTime);
        if (currentTime >= startTimeControl && currentTime <= endTimeControl) {
            return true
        } else {
            return false
        }
    }

    const examExistControl = async (id) => {
        try {
            const response = await studentGetByExamIdApi(id)

            if (!response.data.items) {
                toast.error("Sınavı zaten tamamladınız", {
                    autoClose: 2500
                })
                return false
            }
            return true
        }
        catch (err) {
            return false
        }
    }



    const getAllExamList = async () => {
        try {
            setLoading(true)
            const response = await examGetAllForStudentApi({
                page: 0,
                pageSize: 50
            })
            const responsev2 = await examGetAllForStudentApi({
                page: 0,
                pageSize: response.data.count
            })
            setExamData(responsev2.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const dataList = useMemo(() => {
        if (examTimeType == "0") {
            return examData.filter(el => {
                return TimeControl(el.startTime, el.endTime)
            })
        }
        else if (examTimeType == "1") {
            return examData.filter(el => {
                const currentTime = new Date();
                const startTimeControl = new Date(el.startTime);
                return currentTime < startTimeControl
            })
        }
        else {
            return examData.filter(el => {
                const currentTime = new Date();
                const endTimeControl = new Date(el.endTime);
                return currentTime > endTimeControl
            })
        }
    }, [examData, examTimeType])




    const mainData = useMemo(() => {
        let main = dataList.filter(el => el?.name.toLowerCase()?.search(searchText.toLowerCase()) !== -1)


        return main
    }, [searchText, dataList])




    useEffect(() => {
        getAllExamList()
    }, [])




    return (
        <MainContainer>
            <StyledHeaderContainer>
                <SearchSelectContainer>
                    <StyledSearchInput placeholder="Ara" onChange={(e) => {
                        setSearchText(e.target.value)
                    }} />

                    <StyledSelect
                        value={examTimeType}
                        onChange={(e) => {
                            setExamTimeType(e)
                        }}
                        options={[
                            { value: '0', label: 'Aktif' },
                            { value: '1', label: 'Beklenen' },
                            { value: '2', label: 'Geçmiş' },
                        ]}
                    />
                </SearchSelectContainer>

                {/*   <Button type='primary' onClick={() => {
                    getAllExamList()
                }} >
                    Yenile <RefreshIcon />

                </Button> */}

            </StyledHeaderContainer>

            <StyledAntTable
                loading={loading}
                scroll={{ x: 800 }}
                pagination={{
                    pageSize: 10
                }}
                dataSource={mainData}
                columns={[
                    {
                        title: "Sınav İsmi",
                        dataIndex: "name",
                    },
                    {
                        title: "Kurs İsmi",
                        dataIndex: "courseName",
                        render: (row) => {
                            return (
                                <span style={{ fontSize: "14px" }} >
                                    {row}
                                </span>
                            )
                        }
                    },
                    {
                        title: "Başlangıç",
                        dataIndex: "startTime",
                        render: (row) => {
                            return (
                                <span style={{ fontSize: "14px" }} >
                                    {formatDate(new Date(row), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })}
                                </span>
                            )
                        },
                        sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
                    },
                    {
                        title: "Bitiş",
                        dataIndex: "endTime",
                        render: (row, record) => {
                            return (
                                <span style={{ fontSize: "14px" }} >
                                    {formatDate(new Date(record.endTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })}
                                </span>
                            )
                        },
                        sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime),
                    },
                    {
                        title: "Sınav Süresi",
                        render: (row, record) => {

                            return (
                                <span>
                                    {getMinuteDifference(record?.startTime, record?.endTime)} (Dk)
                                </span>
                            )
                        }
                    },
                    {
                        title: "İşlem",
                        dataIndex: "startTime",
                        render: (row, record) => {
                            if (record?.success) {
                                return (
                                    <Button disabled={examTimeType !== "0"} type="primary" onClick={async () => {
                                        const control = await examExistControl(record.id)
                                        if (control) {
                                            navigation(`/panel/sinav/${record.id}`)
                                        }
                                    }} >
                                        {
                                            examTimeType == 0 ? "Sınava başla" : examTimeType == 1 ? "Bekleniyor" : "süresi geçmiş"
                                        }
                                    </Button>
                                )
                            }
                            else {
                                return (
                                    <Button type='primary' danger >
                                        {record?.message}
                                    </Button>
                                )
                            }
                        }
                    }
                ]}

            />
            {/*  <DataTable
                data={mainData}
                columns={columns}
                pagination
                noDataComponent={
                    <Card className="w-100 p-5" style={{ marginRight: "0" }} >
                        <center>
                            <h2>Herhangi bir veri bulunamadı.</h2>
                        </center>
                    </Card>
                }
                responsive={true}
                paginationComponentOptions={{
                    rowsPerPageText: "Satır Sayısı",
                    rangeSeparatorText: "-",
                    noRowsPerPage: true

                }}
            /> */}
        </MainContainer>
    )
}




const SearchSelectContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 20px;
    width: 100%;
    @media (max-width: 600px) {
      justify-content:space-between;
     margin-bottom:10px;
  }
    @media (max-width: 450px) {
        justify-content: space-between;
        
 /*      display:grid; 
    grid-template-columns: 1fr;
    max-width:150px;
    margin-right:5px; */
  }
  
`

const StyledAntTable = styled(AntTable)`
    .ant-table-content{
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }
`

export default StudentExamDashboard