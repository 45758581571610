import React, { createContext, useEffect, useState } from 'react'
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import CreateExam from './CreateExam/CreateExam'
import ExamListDashboard from './ExamListDashboard/ExamListDashboard'
import { getExamListApi } from '../../api/exam'
import { uniqueId } from 'lodash'
import styled from 'styled-components'
import { Select } from 'antd'

export const ExamContext = createContext()

const ExamManagementDashboard = () => {
    const [loading, setLoading] = useState(false);
    const [examList, setExamList] = useState([]);

    const [searchText, setSearchText] = useState("")
    const [examData, setExamData] = useState({
        "course": "",
        "questions": [],
        "examName": "",
        "description": "",
        "type": "Test",
        "startTime": null,
        "endTime": null,
        "formal": "Vize"
    })
    const [courseList, setCourseList] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalQuestion, setModalQuestion] = useState("")
    const [questionPoint, setQuestionPoint] = useState(0)
    const [poolFlag, setPoolFlag] = useState(false)
    const [editMode, setEditMode] = useState({
        id: 0,
        active: false
    })
    const [modalChoiceList, setModalChoiceList] = useState([{
        "content": "",
        "id": 984,
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    }])
    const [previewSrc, setPreviewSrc] = useState(null);
    const [activeTab, setActiveTab] = useState(1)
    const [questionPoolModal, setQuestionPoolModal] = useState({
        visible: false,
        data: []
    })

    const examListGetALL = async (type = "Test") => {
        try {
            setLoading(true);
            const response = await getExamListApi({
                "page": 0,
                "pageSize": 10
            }, type);
            const totalRow = response.data.count;

            const responsev2 = await getExamListApi({
                "page": 0,
                "pageSize": totalRow
            }, type);
      
            setExamList(responsev2.data.items);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
       
    }, [modalChoiceList])

    return (
        <>
            <ExamContext.Provider value={{
                examData: examData,
                setExamData: setExamData,
                courseList: setCourseList,
                setCourseList,
                modalQuestion,
                setModalQuestion,
                previewSrc,
                setPreviewSrc,
                questionPoint,
                setQuestionPoint,
                editMode,
                setEditMode,
                modalChoiceList,
                setModalChoiceList,
                courseList,
                setCourseList,
                modalShow,
                setModalShow,
                examListGetALL,
                loading,
                setLoading,
                examList,
                setExamList,
                questionPoolModal,
                setQuestionPoolModal,
                poolFlag,
                setPoolFlag
            }} >
                <Nav tabs>
                    <NavItem>
                        <NavLink style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : null} onClick={() => setActiveTab(1)} >
                            Sınavlar
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : null} onClick={() => { setActiveTab(2) }} >
                            Sınav Oluştur
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} >
                    <TabPane tabId={1} >
                        <ExamListDashboard />
                    </TabPane>
                    <TabPane tabId={2} >
                        <CreateExam />
                    </TabPane>
                </TabContent>
            </ExamContext.Provider>


        </>
    )
}

export default ExamManagementDashboard


