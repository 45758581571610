import React, { Suspense } from "react";
import VerticalLayout from "../Layouts/index";
import { AuthProtected } from "./AuthProtected";
import { Route, Routes } from "react-router-dom";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import { authProtectedRoutes, publicRoutes } from "./allRouter";
import LoadingSpinner from "../Components/Common/LoadingSpinner";
import { useSignalR } from "../hooks/useSignalR";

const Index = () => {
 //  useSignalR();

 return (
  <Suspense fallback={<LoadingSpinner />}>
   <Routes>
    <Route>
     {publicRoutes.map((route, idx) => {
      return (
       <Route
        path={route.path}
        element={
         <Suspense fallback={<LoadingSpinner />}>
          <NonAuthLayout>{route.component}</NonAuthLayout>
         </Suspense>
        }
        key={idx}
        exact={true}
       />
      );
     })}
    </Route>
    <Route>
     {authProtectedRoutes.map((route, idx) => {
      const template = route.template ?? true;
      return (
       <Route
        path={route.path}
        element={
         <Suspense fallback={<LoadingSpinner />}>
          <AuthProtected role={route.role}>
           {template ? (
            <VerticalLayout>{route.component}</VerticalLayout>
           ) : (
            <div>{route.component}</div>
           )}
          </AuthProtected>
         </Suspense>
        }
        key={idx}
        exact={true}
       />
      );
     })}
    </Route>
   </Routes>
  </Suspense>
 );
};

export default Index;
