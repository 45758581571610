import { notification } from "antd";
import { NotificationFilled, NotificationTwoTone } from "@ant-design/icons";

// Başarı bildirimi göster
export const showSuccessNotification = (
 message,
 description,
 duration = null
) => {
 notification.success({
  message,
  description,
  duration,
  icon: <NotificationTwoTone color="#00F604" twoToneColor={"#00F604"} />,
 });
};

// Hata bildirimi göster
export const showErrorNotification = (
 message,
 description,
 duration = null
) => {
 notification.error({
  message,
  description,
  duration,
 });
};

// SignalR bildirimlerini işle
export const handleSignalRNotification = (message) => {
 console.log(message);
 showSuccessNotification("KUZEM Bildirim Sistemi", message, null);
};
