import { Alert } from 'antd'
import React from 'react'
import { FaStar, FaUser } from 'react-icons/fa'
import { Card, CardBody, CardFooter, CardImg, CardTitle, Col } from 'reactstrap'
import styled from 'styled-components'
import ButtonPurple from '../../components/buttons/ButtonPurple'
import Rating from 'react-rating'
import { FaRegStar } from 'react-icons/fa6'
import OnlineImg1 from "../../../assets/images/onlineimage1.png"
import OnlineImg2 from "../../../assets/images/onlineimage2.png"
import OnlineImg3 from "../../../assets/images/onlineimage3.png"
import OfflineImg1 from "../../../assets/images/img1.png"
import OfflineImg2 from "../../../assets/images/imgcard2.png"
import OfflineImg3 from "../../../assets/images/imgcard3.png"
import OfflineImg4 from "../../../assets/images/imgcard4.png"
import OfflineImg5 from "../../../assets/images/imgcard5.png"
import { useNavigate } from 'react-router-dom'


const EgitmenCourses = ({ courseList }) => {
    const onlineImags = [OnlineImg1, OnlineImg2, OnlineImg3]
    const offlieImages = [OfflineImg1, OfflineImg2, OfflineImg3, OfflineImg4, OfflineImg5]
    const navigate = useNavigate()
    function generateRandomNumber(max) {
        // 0'dan max'a kadar bir sayı üret
        return Math.floor(Math.random() * (max));
    }


    const getImg = (item) => {
        if (item?.branch?.url == "0" || item?.branch?.url == null || item?.branch?.url == undefined) {
            if (item?.branch?.type == "Online") {
                return OnlineImg1
            }
            else {
                return OfflineImg1
            }
        }
        else {
            return item?.branch?.url
        }

    }

    

    return (
        <>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {courseList?.length !== 0 ? courseList.map((item, index) => {
                    return (
                        <Col md={6} lg={3} key={index}>
                            <StyledCard onClick={() => {

                                navigate(`/kurs/${item?.branch?.label}`)
                            }}  >
                                <StyledCardImg variant="top" src={getImg(item)} id='img' />
                                <StyledCardBody >
                                    <CardTitle id='doubleImg' style={{ display: "flex", alignItems: "center" }}>
                                        <StyledCardBodyElement >
                                            <p className="">
                                                {item?.name}
                                            </p>
                                            <Rating
                                                initialRating={5}
                                                emptySymbol={<FaRegStar color="#ffc107" size={15} />}
                                                fullSymbol={<FaStar color="#ffc107" size={15} />}
                                                readonly
                                                className="rating"
                                            />
                                        </StyledCardBodyElement>

                                    </CardTitle>

                                </StyledCardBody>

                                {/*  <StyledCardFooter >
                                    <div className="footer_container">
                                        <div className='person_count'>
                                            <FaUser />
                                            <span>
                                                544
                                            </span>
                                        </div>
                                    </div>
                                </StyledCardFooter> */}
                            </StyledCard>
                        </Col>
                    )
                }) : (
                    <Alert type='warning' message="Kurs bulunamadı" style={{ width: "100%", textAlign: "center" }} />

                )}
            </div>
        </>
    )
}

export default EgitmenCourses


const StyledCardBody = styled(CardBody)`
`

const StyledCard = styled(Card)`
border: 1px solid transparent;
cursor: pointer;
/* margin-right: 0 !important; */

    @media screen and (max-width:600px) {
        margin-right: 0px;
    }
`

const StyledCardFooter = styled(CardFooter)`
  width: 100%;
  z-index: -122;

  .footer_container{
    display: flex;
    justify-content: space-between;

    .person_count{
      display: flex;
      align-items: center;
      font-size: 16px;
      column-gap: 5px;
    }
  }
`
const StyledCardBodyElement = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-height: 120px;
`

const StyledCardImg = styled(CardImg)`
    max-height: 170px;
    min-height: 170px;
`
