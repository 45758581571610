import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../Components/Common/withRouter";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

//import actions
import {
 changeLayout,
 changeSidebarTheme,
 changeLayoutMode,
 changeLayoutWidth,
 changeLayoutPosition,
 changeTopbarTheme,
 changeLeftsidebarSizeType,
 changeLeftsidebarViewType,
 changeSidebarImageType,
 changeSidebarVisibility,
} from "../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import AntSidebar from "./AntSidebar";

const Layout = (props) => {
 const [headerClass, setHeaderClass] = useState("");
 const dispatch = useDispatch();
 const {
  layoutType,
  leftSidebarType,
  layoutModeType,
  layoutWidthType,
  layoutPositionType,
  topbarThemeType,
  leftsidbarSizeType,
  leftSidebarViewType,
  leftSidebarImageType,
  sidebarVisibilitytype,
 } = useSelector((state) => ({
  layoutType: state.Layout.layoutType,
  leftSidebarType: state.Layout.leftSidebarType,
  layoutModeType:
   localStorage.getItem("layoutModeType") || state.Layout.layoutModeType,
  layoutWidthType: state.Layout.layoutWidthType,
  layoutPositionType: state.Layout.layoutPositionType,
  topbarThemeType: state.Layout.topbarThemeType,
  leftsidbarSizeType: state.Layout.leftsidbarSizeType,
  leftSidebarViewType: state.Layout.leftSidebarViewType,
  leftSidebarImageType: state.Layout.leftSidebarImageType,
  sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
 }));

 useEffect(() => {
  if (
   layoutType ||
   leftSidebarType ||
   layoutModeType ||
   layoutWidthType ||
   layoutPositionType ||
   topbarThemeType ||
   leftsidbarSizeType ||
   leftSidebarViewType ||
   leftSidebarImageType ||
   sidebarVisibilitytype
  ) {
   window.dispatchEvent(new Event("resize"));
   dispatch(changeLeftsidebarViewType(leftSidebarViewType));
   dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
   dispatch(changeSidebarTheme(leftSidebarType));
   dispatch(changeLayoutMode(layoutModeType));
   dispatch(changeLayoutWidth(layoutWidthType));
   dispatch(changeLayoutPosition(layoutPositionType));
   dispatch(changeTopbarTheme(topbarThemeType));
   dispatch(changeLayout(layoutType));
   dispatch(changeSidebarImageType(leftSidebarImageType));
   dispatch(changeSidebarVisibility(sidebarVisibilitytype));
  }
 }, [
  layoutType,
  leftSidebarType,
  layoutModeType,
  layoutWidthType,
  layoutPositionType,
  topbarThemeType,
  leftsidbarSizeType,
  leftSidebarViewType,
  leftSidebarImageType,
  sidebarVisibilitytype,
  dispatch,
 ]);

 useEffect(() => {
  window.addEventListener("scroll", scrollNavigation, true);
 });

 function scrollNavigation() {
  var scrollup = document.documentElement.scrollTop;
  if (scrollup > 50) {
   setHeaderClass("topbar-shadow");
  } else {
   setHeaderClass("");
  }
 }

 useEffect(() => {
  if (
   sidebarVisibilitytype === "show" ||
   layoutType === "vertical" ||
   layoutType === "twocolumn"
  ) {
   document.querySelector(".hamburger-icon").classList.remove("open");
  } else {
   document.querySelector(".hamburger-icon").classList.add("open");
  }
 }, [sidebarVisibilitytype, layoutType]);

 return (
  <React.Fragment>
   <div id="layout-wrapper">
    <Sidebar layoutType={layoutType} />
    <div className="main-content">
     <Header />
     {props.children}
     <Footer />
    </div>
   </div>
  </React.Fragment>
 );
};

Layout.propTypes = {
 children: PropTypes.object,
};

export default withRouter(Layout);
