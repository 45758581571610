import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {
 HttpTransportType,
 HubConnectionBuilder,
 HubConnectionState,
 JsonHubProtocol,
 LogLevel,
} from "@microsoft/signalr";
import { handleSignalRNotification } from "../utils/notificationUtils";

export const useSignalR = () => {
 const navigate = useNavigate();
 const [hub, setHub] = useState(null);
 const baseURL = process.env.REACT_APP_API_URL;
 const accessToken = localStorage.getItem("authToken");

 useLayoutEffect(() => {
  if (accessToken) connectSignalR();
  window.addEventListener("beforeunload", stopSignalR);
  return () => {
   stopSignalR();
   window.removeEventListener("beforeunload", stopSignalR);
  };
 }, []);

 useLayoutEffect(() => {
  if (hub) listenSignalR();
 }, [hub]);

 const connectSignalR = async (retries = 1) => {
  const options = {
   logMessageContent: true,
   logger: LogLevel.Warning,
   skipNegotiation: true,
   transport: HttpTransportType.WebSockets,
   accessTokenFactory: () => accessToken,
  };

  const hubConnection = new HubConnectionBuilder()
   .withUrl(baseURL.slice(0, -4) + "/notificationHub", options)
   .withAutomaticReconnect()
   .withHubProtocol(new JsonHubProtocol())
   .configureLogging(LogLevel.Information)
   .build();

  hubConnection.serverTimeoutInMilliseconds = 60000;
  hubConnection.keepAliveIntervalInMilliseconds = 15000;

  hubConnection.onclose((error) => {
   console.assert(hubConnection.state === HubConnectionState.Disconnected);
   if (!!error) {
    console.log("SignalR: connection was closed due to error.", error);
   } else {
    console.log("SignalR: connection was closed.");
   }
  });

  setHub(hubConnection);

  await hubConnection
   .start()
   .then(() => {
    console.log("SignalR Bağlantısı Kuruldu");
   })
   .catch(async (err) => {
    console.log(err.message);
    if (err.statusCode === 401 && retries < 4) {
     const isTokenRefreshed = await refreshTokenHandler();
     if (isTokenRefreshed) connectSignalR(retries + 1);
     else navigate("/giris");
    }
    console.log("SignalR Bağlantısı Kurulamadı");
    console.log(err);
   });
 };

 const refreshTokenHandler = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
   try {
    const { data } = await axios.post(
     `${baseURL}/auth/CreateAccessTokenWithRefreshToken`,
     refreshToken,
     {
      headers: {
       Accept: "*/*",
       "Content-Type": "application/json-patch+json",
      },
     }
    );

    localStorage.setItem("authToken", data.accessToken.token);
    localStorage.setItem("refreshToken", `"${data.refreshToken.token}"`);
    return true;
   } catch {
    console.log(err);
    return false;
   }
  }
 };

 const stopSignalR = () => {
  if (hub) {
   hub.stop();
   setHub(null);
  }
 };

 const listenSignalR = () => {
  hub.on("ExamNotification", (message) => {
   handleSignalRNotification(message);
  });
 };

 return {
  hub,
 };
};
