import { MenuOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProfileDropdown from "../Components/Common/ProfileDropdown";
import { changeSidebarVisibility } from "../slices/thunks";
import { useSelector, useDispatch } from "react-redux";

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
 const dispatch = useDispatch();

 const { sidebarVisibilitytype } = useSelector((state) => ({
  sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
 }));

 const [search, setSearch] = useState(false);
 const toogleSearch = () => {
  setSearch(!search);
 };

 const toogleMenuBtn = () => {
  var windowSize = document.documentElement.clientWidth;
  dispatch(changeSidebarVisibility("show"));

  if (windowSize > 767)
   document.querySelector(".hamburger-icon").classList.toggle("open");

  if (document.documentElement.getAttribute("data-layout") === "horizontal") {
   document.body.classList.contains("menu")
    ? document.body.classList.remove("menu")
    : document.body.classList.add("menu");
  }

  if (
   sidebarVisibilitytype === "show" &&
   (document.documentElement.getAttribute("data-layout") === "vertical" ||
    document.documentElement.getAttribute("data-layout") === "semibox")
  ) {
   if (windowSize < 1025 && windowSize > 767) {
    document.body.classList.remove("vertical-sidebar-enable");
    document.documentElement.getAttribute("data-sidebar-size") === "sm"
     ? document.documentElement.setAttribute("data-sidebar-size", "")
     : document.documentElement.setAttribute("data-sidebar-size", "sm");
   } else if (windowSize > 1025) {
    document.body.classList.remove("vertical-sidebar-enable");
    document.documentElement.getAttribute("data-sidebar-size") === "lg"
     ? document.documentElement.setAttribute("data-sidebar-size", "sm")
     : document.documentElement.setAttribute("data-sidebar-size", "lg");
   } else if (windowSize <= 767) {
    document.body.classList.add("vertical-sidebar-enable");
    document.documentElement.setAttribute("data-sidebar-size", "lg");
   }
  }

  //Two column menu
  if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
   document.body.classList.contains("twocolumn-panel")
    ? document.body.classList.remove("twocolumn-panel")
    : document.body.classList.add("twocolumn-panel");
  }
 };

 return (
  <React.Fragment>
   <header style={{ marginTop: "0.5rem" }} id="" className={headerClass}>
    <div className="layout-width">
     <div className="navbar-header">
      <div className="d-flex">
       <div
        className="navbar-brand-box horizontal-logo"
        style={{ alignItems: "center", justifyContent: "center" }}
       >
        <Link
         to="/"
         className="logo logo-dark"
         style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "32px",
         }}
        >
         {/* <img
          src={KuzemBlueLogo}
          alt="" loading="lazy" 
          style={{
           objectFit: "contain",
           height: "80%",
          }}
         /> */}

         {/*  <span className="logo-lg">
                                        <img src={logo} alt="" height="17" />
                                    </span> */}
        </Link>

        <Link
         to="/"
         className="logo logo-light"
         style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "32px",
         }}
        >
         Kuzem
         {/*  <span className="logo-lg">
                                        <img loading="lazy"  src={logo} alt="" height="17"  loading="lazy" />
                                    </span> */}
        </Link>
       </div>

       <button
        onClick={toogleMenuBtn}
        type="button"
        className="btn btn-sm  fs-16 header-item vertical-menu-btn topnav-hamburger"
        id="topnav-hamburger-icon"
       >
        <MenuOutlined />
        <span className="hamburger-icon" style={{ display: "none" }}>
         <span></span>
         <span></span>
         <span></span>
        </span>
       </button>
      </div>

      <div className="d-flex align-items-center">
       {/* FullScreenDropdown */}
       {/* <FullScreenDropdown /> */}

       {/* Dark/Light Mode set */}
       {/* <LightDark layoutMode={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} /> */}

       {/* ProfileDropdown */}
       <ProfileDropdown />
      </div>
     </div>
    </div>
   </header>
  </React.Fragment>
 );
};

export default Header;
