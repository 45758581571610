import "./i18n";
import "./index.css";
import App from "./App";
import React from "react";
import store from "./slices/index";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { UserStore } from "./context/user";
import { ToastContainer } from "react-toastify";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
 <HelmetProvider>
  <Provider store={store}>
   <BrowserRouter>
    <UserStore>
     <ToastContainer />
     <ConfigProvider
      theme={{
       components: {
        Table: {
         headerBg: "black",
         headerColor: "white",
         headerSortHoverBg: "#000000dd",
         headerSortActiveBg: "#000000cc",
         headerFilterHoverBg: "#ffffffcc",
         footerBg: "transparent",
        },
        Button: {},
       },
       token: {
        colorIcon: "white",
       },
      }}
     >
      <App />
     </ConfigProvider>
    </UserStore>
   </BrowserRouter>
  </Provider>
 </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
