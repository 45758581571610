import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../../api/axiosInstance'
import { Button as AntButton, Table as AntTable, Button, Modal, Select } from "antd"
import { getAllBranch } from '../../../api/Branch'
import { useParams } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const TeacherBranch = () => {
    const { id } = useParams()
    const [teacherBranches, setTeacherBranches] = useState([])
    const [allBranch, setAllBranch] = useState([])
    const [selectedBranch, setSelectedBranch] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        try {
            const response = await axiosInstance.post("/TeacherBranches/Add", {
                userId: id,
                branchId: selectedBranch
            })
            toast.success("Öğretmen için branş eklendi", {
                autoClose: 2000,
                position: "top-center"
            })
            setIsModalOpen(false);
            setSelectedBranch()
            getTeacherBranchList()
            getAllBranchRequest()
        }
        catch (err) {

        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedBranch()
    };
    const getTeacherBranchList = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`/TeacherBranches/GetAllByUserId?userId=${id}`, {
                page: 0,
                pageSize: 10000
            })
            const mainData = response.data.items?.map(el => {
                return {
                    name: el?.branch?.name,
                    id: el?.id
                }
            })
           
            setTeacherBranches(mainData)
        }
        catch (err) {
            
        }
        finally {
            setLoading(false)
        }
    }

    const getAllBranchRequest = async () => {
        try {
            setLoading(true)
            const response = await getAllBranch({
                page: 0,
                pageSize: 10000
            })
            setAllBranch(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getTeacherBranchList()
        getAllBranchRequest()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>
                <StyledButton type='primary' onClick={showModal}>
                    Ekle
                </StyledButton>

            </StyledHeader>

            <AntTable
                scroll={{
                    x: 400
                }}
                size='middle'
                dataSource={teacherBranches}
                pagination={{
                    pageSize: 5
                }}
                columns={[
                    {
                        title: "İsim",
                        render: (row, record) => {
                            return (
                                <span> {record?.name} </span>
                            )
                        }
                    },
                    {
                        title: "Sil",
                        render: (row, record) => {
                            return (
                                <AntButton icon={<FaTrash />} type='primary' danger onClick={() => {
                                    Modal.confirm({
                                        title: "Silmek istediğinize emin misiniz?",
                                        cancelText: "iptal",
                                        okText: "eminim",
                                        onOk: async () => {
                                            try {
                                                const response = await axiosInstance.delete(`/TeacherBranches/Delete/${record?.id}`)
                                                toast.success("Öğretmenin branşı silindi", {
                                                    autoClose: 2000,
                                                    position: "top-center"
                                                })
                                                getTeacherBranchList()
                                                getAllBranchRequest()
                                            }
                                            catch (err) {

                                            }
                                        }
                                    })
                                }} >
                                    Sil
                                </AntButton>
                            )
                        }
                    }
                ]}
            />
            <Modal title="Branş Ekle" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okButtonProps={{
                disabled: selectedBranch ? false : true
            }} >
                <div style={{ margin: "20px 0" }}>
                    <StyledSelect
                        options={allBranch.map(el => {
                            return {
                                value: el.id,
                                label: el.name
                            }
                        })}
                        onChange={(value) => {
                            setSelectedBranch(value)
                        }}
                        value={selectedBranch}
                    />

                </div>

            </Modal>



        </StyledContainer>

    )
}

export default TeacherBranch


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`

const StyledHeader = styled.header`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px dashed gray;
    padding: 5px 20px;
    border-radius: 10px;
`

const StyledButton = styled(Button)`
    width: 100px;
`

const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`