import React, { useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import { GetCalendarForAllRoles } from '../../api/Course'
import { Col, Row } from 'reactstrap'
import { Button as AntButton, Select as AntSelect, Table as AntTable, Button, Input } from "antd"
import styled from 'styled-components'
import { MdRefresh } from 'react-icons/md'
import { format } from "date-fns"
import trLocale from "date-fns/locale/tr"
import { FaFileArchive, FaPlus } from 'react-icons/fa'
import EditOnlineContentModal from '../../Components/Course/UpdateCourse/EditOnlineCourseContent/EditOnlineContentModal'
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'
import { formatInTimeZone } from 'date-fns-tz'
import TeacherAddCourseWorkModal from './TeacherAddCourseWorkModal'
import { FaFile } from 'react-icons/fa6'
import TeacherViewCourseWork from './TeacherViewCourseWork'

const TeacherOnlineMeetingPage = () => {
    const [loading, setLoading] = useState(false)
    const [mainData, setMainData] = useState([])
    const [activeType, setActiveType] = useState(0)
    const [contentModal, setContentModal] = useState({
        visible: false,
        data: null
    })
    const [addCourseWorkModal, setAddCourseWorkModal] = useState({
        visible: false,
        calendarId: ""
    })
    const [viewCourseWorkModal, setViewCourseWorkModal] = useState({
        visible: false,
        courseWork: null
    })
    const [currentPage, setCurrentPage] = useState(1)
    const openJoinUrl = (url) => {
        window.open(url, "_blank")
    }

    const getTeacherCourse = async () => {
        try {
            setLoading(true)
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 10
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })
            let data = responsev2.data.items
            setMainData(data?.filter(el => el?.type == "Online"))
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }

    }

    function filtreleIleriTarihler(courses, status = 0) {
        if (status == 0) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var ileriTarihler = courses?.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi > suankiTarih; // İleri tarihlerde olanları filtrele
            });
            ileriTarihler.sort(function (a, b) {
                return new Date(a.startTime) - new Date(b.startTime);
            });
            return ileriTarihler;  //bekleyenler
        }
        else if (status == 1) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var gecmisTarihler = courses.filter(function (course) {
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBitisTarihi < suankiTarih; // Geçmiş tarihlerde olanları filtrele
            });
            gecmisTarihler.sort(function (a, b) {
                return new Date(b.startTime) - new Date(a.startTime);
            });
            return gecmisTarihler;
        }
        else {
            var suankiTarih = new Date();
            var simdikiZamandakiKurslar = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBaslangicTarihi < suankiTarih && suankiTarih < kursBitisTarihi; // Şu anki zamanın aralıkta olup olmadığını kontrol et
            });
            simdikiZamandakiKurslar.sort(function (a, b) {
                return Math.abs(new Date(a.startTime) - suankiTarih) - Math.abs(new Date(b.startTime) - suankiTarih);
            });
            return simdikiZamandakiKurslar;

        }
    }

    const tempData = useMemo(() => {
        console.log("mainDatax", mainData)
        let test = mainData?.map(item => {
            if (item?.courseCalendars?.length !== 0) {
                return item?.courseCalendars?.map(el => {
                    const { courseCalendars, ...restElements } = item
                    const { onlineCourseUrls, ...rest } = el
                    return {
                        ...restElements,
                        ...rest,
                        courseCalendarId: rest?.id,
                        onlineCourseUrls: onlineCourseUrls[0]
                    }
                })
            }

        }).flatMap(x => x).filter(x => x != undefined)
        if (activeType == 0) {
            const ileriTarih = filtreleIleriTarihler(test, 0)
            return ileriTarih
        }
        else if (activeType == 1) {
            const gecmis = filtreleIleriTarihler(test, 1)
            return gecmis
        }
        else {
            const active = filtreleIleriTarihler(test, 2)

            return active
        }
    }, [mainData, activeType])




    const joinLink = (row) => {
        if (activeType == 0) {
            return <AntButton disabled style={{ backgroundColor: "#FF9800", border: "none", color: "white", pointerEvents: "none", fontSize: "12px" }}  >
                Zaman Bekleniyor
            </AntButton>
        }
        else if (activeType == 1) {
            return <AntButton danger type='primary' style={{ pointerEvents: "none" }} >
                Link Aktif Değil
            </AntButton>
        }
        else {

            if (row?.onlineCourseUrls?.length == 0 || row?.onlineCourseUrls == undefined) {
                return (
                    <AntButton type='primary' style={{ border: "none", color: "white", fontSize: "12px", pointerEvents: "none" }} onClick={() => {
                    }} >
                        Link henüz oluşmadı ,yenileyiniz
                    </AntButton>
                )
            }
            else if (row?.onlineCourseUrls?.meetingUrl == "Zaman Bekleniyor.") {

                return (
                    <AntButton type='primary' style={{ border: "none", color: "white", fontSize: "12px" }} onClick={async () => {
                        try {
                            const response = await axiosInstance.put("/onlineCourseUrls/StartOnlineCourse", {
                                calendarId: row?.onlineCourseUrls?.courseCalendarId
                            })
                            toast.success("Canlı yayın oluşturuldu katıl butonu üzerinden eğitime katılabilirsiniz", {
                                position: "top-center",
                                autoClose: 2000
                            })
                            await getTeacherCourse()
                        }
                        catch (err) {

                        }
                    }} >
                        Eğitimi Oluştur
                    </AntButton>
                )
            }
            else {
                return (
                    <AntButton type='primary' style={{ border: "none", color: "white", fontSize: "12px" }} onClick={() => {
                        openJoinUrl(row?.onlineCourseUrls?.meetingUrl)
                    }} >
                        Eğitime Katıl
                    </AntButton>
                )
            }


            /* if (row?.onlineCourseUrls?.length == 0 || row?.onlineCourseUrls == undefined || row?.onlineCourseUrls?.meetingUrl == "Zaman Bekleniyor.") {
                return (
                    <AntButton type='primary' style={{ border: "none", color: "white", fontSize: "12px", pointerEvents: "none" }} onClick={() => {
                    }} >
                        Link henüz oluşmadı ,yenileyiniz
                    </AntButton>
                )
            }
            else {
                return <AntButton type='primary' style={{ border: "none", color: "white", fontSize: "12px" }} onClick={() => {
                    openJoinUrl(row.onlineCourseUrls.meetingUrl)
                }} >
                    Katıl
                </AntButton>
            } */

        }
    }


    useEffect(() => {
        getTeacherCourse()
    }, [])


    const convertTime = (time) => {
        /* const parsedDate = parseISO(time); */
        /* const formattedDate = format(time, "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale }); */
        const formattedDate = formatInTimeZone(time, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
        return formattedDate
    }

    const KursEklemeHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    width: 100%;
    border: 1px dashed gray;
    padding: 10px;
    border-radius: 10px;
`

    const [searchTextName, setSearchTextName] = useState("")
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchTextName(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchTextName('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset

                </Button>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });


    return (
        <div className='page-content' >
            <TablePageContainer header={"Canlı Derslerim"} visible={false} >
                <StyledContainer>
                    <Row>
                        <Col lg={12} >
                            <KursEklemeHeader   >
                                <StyledAntSelect
                                    value={activeType}
                                    onChange={(e) => {
                                        setActiveType(e)
                                    }}
                                    options={[
                                        { value: 0, label: 'Bekleyen' },
                                        { value: 1, label: 'Geçmiş' },
                                        { value: 2, label: 'Aktif' },

                                    ]}
                                />


                                <AntButton type='primary' style={{ display: "flex", alignItems: "center", columnGap: 10 }} onClick={() => {


                                    getTeacherCourse()


                                }} >
                                    Yenile<MdRefresh style={{ fontSize: "18px" }} />
                                </AntButton>


                            </KursEklemeHeader>
                        </Col>
                        <Col lg={12} >
                            <StyledDataTableContainer>
                                <AntTable
                                    loading={loading}
                                    dataSource={tempData}
                                    scroll={{
                                        x: 800
                                    }}
                                    size='middle'
                                    pagination={{
                                        pageSize: 10,
                                        current: currentPage,
                                        onChange: (e) => {
                                            setCurrentPage(e)
                                        }
                                    }}
                                    columns={[
                                        {
                                            title: "Kurs Id",
                                            dataIndex: "courseId",
                                            render: (row, record) => {
                                                return (
                                                    <span>
                                                        {
                                                            record?.courseId
                                                        }
                                                    </span>
                                                )
                                            },
                                            ...getColumnSearchProps("courseId")
                                        },
                                        {
                                            title: "CalendarId",
                                            dataIndex: "courseCalendarId",
                                            render: (row, record) => {
                                                return (
                                                    <p>
                                                        {record?.courseCalendarId}
                                                    </p>
                                                )
                                            },
                                            ...getColumnSearchProps("courseCalendarId")
                                        },
                                        {
                                            title: "İsim",
                                            dataIndex: "name",
                                            render: (row, record) => {
                                                return (
                                                    <span>
                                                        {record.name.length > 30 ? `${record.name.substring(0, 30)}...` : record.name}
                                                    </span>
                                                )
                                            },
                                            ...getColumnSearchProps("name")
                                        },
                                        {
                                            title: "Başlangıç",
                                            render: (row, record) => {
                                                return (
                                                    <p>
                                                        {
                                                            convertTime(record?.startTime)
                                                        }
                                                    </p>
                                                )
                                            },
                                            sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
                                        },
                                        {
                                            title: "Kurs Süresi",
                                            render: (row, record) => {
                                                return (
                                                    <span>
                                                        {row.duration} (Dk)
                                                    </span>
                                                )
                                            },
                                            sorter: (a, b) => a?.duration - b?.duration
                                        },
                                        {
                                            title: "Kurs Dosyaları",
                                            render: (row, record) => {
                                                /* backgroundColor: "#088F7D" */
                                                return (
                                                    <StyledCourseFilesBtn
                                                        style={
                                                            record?.onlineCourseFiles?.length != 0 ? {
                                                                backgroundColor: "#088F7D"
                                                            } : null
                                                        }
                                                        type='primary' onClick={() => {
                                                            setContentModal({
                                                                visible: true,
                                                                data: record
                                                            })
                                                        }} >
                                                        {
                                                            record?.onlineCourseFiles?.length != 0 ? (
                                                                <p className='files_count' >
                                                                    {
                                                                        record?.onlineCourseFiles?.length
                                                                    }
                                                                </p>
                                                            ) : null
                                                        }

                                                        <FaFileArchive />
                                                    </StyledCourseFilesBtn>
                                                )
                                            }
                                        },
                                        {
                                            title: "Ödev",
                                            render: (row, record) => {

                                                return (
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        columnGap: "7px"
                                                    }} >
                                                        <StyledCourseWorkButton type='primary' onClick={() => {
                                                            setViewCourseWorkModal({
                                                                visible: true,
                                                                courseWork: record?.courseWork
                                                            })
                                                        }} >
                                                            <FaFile />
                                                            {
                                                                record?.courseWork && (
                                                                    <span className='course_work_count' >
                                                                        1
                                                                    </span>
                                                                )
                                                            }
                                                        </StyledCourseWorkButton>
                                                        <StyledAddCourseWorkBtn type='primary' onClick={() => {
                                                            setAddCourseWorkModal({
                                                                visible: true,
                                                                calendarId: record?.courseCalendarId
                                                            })
                                                        }} >
                                                            <FaPlus />
                                                        </StyledAddCourseWorkBtn>
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            title: "Bağlantı",
                                            render: (row, record) => {
                                                return joinLink(record)
                                            }
                                        }
                                    ]}
                                />

                            </StyledDataTableContainer>

                        </Col>

                    </Row >
                    <EditOnlineContentModal apiRequest={getTeacherCourse} calendarList={mainData?.map(el => ({
                        onlineCourseFiles: el?.onlineCourseFiles,
                        id: el?.id,
                        courseId: el.courseId,
                        duration: el?.duration
                    }))} modalData={contentModal} setModalData={setContentModal} addFile={true} />

                    <TeacherAddCourseWorkModal refreshMethod={() => {
                        getTeacherCourse()
                    }} modalInfo={addCourseWorkModal} setModalInfo={setAddCourseWorkModal} />

                    <TeacherViewCourseWork modalInfo={viewCourseWorkModal} setModalInfo={setViewCourseWorkModal} refreshMethod={() => {
                        getTeacherCourse()
                    }} />
                </StyledContainer>


            </TablePageContainer>
        </div>
    )
}

export default TeacherOnlineMeetingPage


const StyledDataTableContainer = styled.div`
    margin-top: 20px;
`

const StyledAntSelect = styled(AntSelect)`
    width: 200px;
    
    .ant-select-selector {
  border: 1px solid #3498db !important; /* Burada istediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */
}
`

const StyledContainer = styled.div`
    padding: 10px 10px ;
`

const StyledCourseFilesBtn = styled(AntButton)`
    position: relative;
    .files_count{
        position: absolute;
        top: -5px;
        left: -10px;
        background-color: #FF9800;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        z-index: 100000;
    }
`

const StyledAddCourseWorkBtn = styled(AntButton)`
    background-color: green;

    &:hover{
        opacity: .7;
        background-color: green !important;
    }
`

const StyledCourseWorkButton = styled(AntButton)`
    position: relative;
    transition: .3s all;


    .course_work_count{
        position: absolute;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: green;
        border-radius: 50%;
        top: -8px;
        left: -8px;
    }
`