import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { MdModeEdit } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';
import { useFormik } from 'formik';
import DataTable from 'react-data-table-component';
import * as yup from "yup"
import { createCategoryApi, getAllCategories, updateCategoryApi } from '../../api/Category';
import { toast } from 'react-toastify';
import { CategoryHeader, MainCategoryContainer, StyledCategoryAntButton, StyledCategorySearchInput, StyledSearchInput } from './CategoryStyle';
import { Button as AntButton, Table as AntTable } from "antd"
import { FcStatistics } from "react-icons/fc";
import CategoryStatisticModal from './CategoryStatisticModal';

const CategoryDashboard = () => {
    const [category, setCategory] = useState([])
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryStatisticModal, setCategoryStatisticModal] = useState({
        visible: false,
        data: null
    })
    const [modalShow, setModalShow] = useState({
        type: "create",
        show: false
    })




    const getCategories = async (page) => {
        try {
            const response = await getAllCategories({
                page: page,
                pageSize: 10000
            })
            setCategory(response.data.items)
        }
        catch (err) {

        }
    }
    useEffect(() => {
        getCategories()
    }, [])



    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
        },
        validationSchema: yup.object({
            name: yup.string().required("İsim Giriniz"),
        }),
        onSubmit: async (value, { resetForm }) => {
            try {
                if (modalShow.type == "create") {
                    const response = await createCategoryApi({
                        name: value.name
                    })
                    await getCategories()
                    resetForm()
                    toast.success("Kategori Kayıt edildi", {
                        autoClose: 1500
                    })
                    setModalShow({
                        type: "create",
                        show: false
                    })
                }
                else {

                    const response = await updateCategoryApi({
                        id: formik.values.id,
                        name: formik.values.name
                    })
                    resetForm()
                    setModalShow({
                        type: "create",
                        show: false
                    })
                    toast.success("Kategori Güncellendi", {
                        autoClose: 1500
                    })
                    getCategories()
                }
            }
            catch (err) {

            }

        }
    })

    const categoryData = useMemo(() => {
        return category.filter(el => el.name.toLowerCase().search(searchTerm.toLowerCase()) !== -1)
    }, [category, searchTerm])

    return (
        <>
            <MainCategoryContainer >
                <CategoryHeader>
                    <StyledCategorySearchInput
                        placeholder="Arama yap..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                        }}
                    />


                    <StyledCategoryAntButton type='primary' onClick={() => {
                        setModalShow({
                            show: true,
                            type: "create"
                        })
                    }}>
                        Kategori Ekle
                    </StyledCategoryAntButton>


                </CategoryHeader>



            </MainCategoryContainer>

            <AntTable
                dataSource={categoryData}
                pagination={{
                    pageSize: 10
                }}
                scroll={{
                    x: 400
                }}
                footer={() => {
                    return (
                        <p style={{
                            fontWeight: "bold"
                        }}>
                            Toplam : {categoryData.length}
                        </p>
                    )
                }}
                size="middle"
                columns={[{
                    title: "İsim",
                    render: (row, record) => {
                        return (
                            <span style={{ textTransform: "capitalize" }}  >  {record?.name.length > 30 ? `${record?.name?.substring(0, 30)}...` : record.name} </span>
                        )
                    }
                },
                {
                    title: "İşlemler",
                    render: (row, record) => {
                        return (
                            <div style={{ columnGap: "15px", display: "flex" }} >
                                <AntButton type='primary' style={{ backgroundColor: "#F7B84B" }} color="warning" onClick={() => {
                                    const selectedBranch = category.find(el => el.id == row.id)
                                    formik.setFieldValue("name", selectedBranch?.name)
                                    formik.setFieldValue("id", selectedBranch?.id)
                                    setModalShow({
                                        type: "edit",
                                        show: true
                                    })
                                }} >
                                    <MdModeEdit />
                                </AntButton>
                                <AntButton type='primary' onClick={() => {

                                    setCategoryStatisticModal({
                                        visible: true,
                                        data: row
                                    })
                                }} >
                                    <FcStatistics />

                                </AntButton>
                            </div>
                        )
                    }

                }

                ]}
            />

            <CategoryStatisticModal modalData={categoryStatisticModal} setModalData={setCategoryStatisticModal} />
            <Modal isOpen={modalShow.show}>
                <ModalHeader>
                    {modalShow.type == "edit" ? "Güncelle" : "Kategori Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="mb-3">
                                {/* <Label htmlFor="firstnameInput" className="form-label">
                                    İsim
                                </Label> */}
                                <Input style={{ marginLeft: 0 }} type="text" className="form-control" id="name" name='name'
                                    placeholder='isim'
                                    value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    invalid={
                                        formik.touched.name && formik.errors.name ? true : false
                                    }
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <FormFeedback type="invalid"><div>{formik.errors.name}</div></FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <AntButton type='primary' onClick={() => {
                        formik.handleSubmit()
                    }} >
                        {
                            modalShow.type == "edit" ? "Güncelle" : "Ekle"
                        }
                    </AntButton>
                    <AntButton danger type='primary' onClick={() => {
                        setModalShow({
                            show: false,
                            type: "create"
                        })

                        formik.resetForm()
                    }} >
                        İptal
                    </AntButton>
                </ModalFooter>
            </Modal>


        </>
    )
}

export default CategoryDashboard

