import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from 'react'
import { Button, FormGroup, Input, Label, ListGroup, ListGroupItem } from 'reactstrap'
import "./ExamDashboard.scss"
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ExamQuestions from '../ExamQuestion/ExamQuestions';
import { createContext } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { getExamDetailByIdApi, studentGetByExamIdApi } from '../../../api/exam';
import LoadingElement from '../../../Components/Loader/LoadinElement';
import styled from 'styled-components';
import { getMinuteDifference, isTimeBeforeJwtExpiration } from '../../../utils/utils';
import { UserContext } from '../../../context/user';
import { Modal, Button as AntButton } from 'antd';
import { toast } from 'react-toastify';

export const ExamContext = createContext()


const ExamDashboard = () => {
    const [loading, setLoading] = useState(false)
    const handle = useFullScreenHandle();
    const { id } = useParams()
    const [stepExam, setStepExam] = useState(0)
    const [detailExam, setDetailExam] = useState()
    const [examInfo, setExamInfo] = useState({
        started: false
    })
    const [checked, setChecked] = useState(false)
    const [context, dispatch] = useContext(UserContext)
    const navigate = useNavigate()

    const ExamStepMatch = () => {
        if (stepExam == 0) {
            return (
                <StyledContentWrap >
                    <div >
                        <ListGroup numbered flush >
                            <StyledListGroupItem  >
                                Sınav süresi KUZEM tarafından belirlenmiştir.
                            </StyledListGroupItem>
                            <StyledListGroupItem  >
                                Sınav süresi tamamlandıktan sonra herhangi bir işlem yapılamamaktadır.
                            </StyledListGroupItem>
                            <StyledListGroupItem >
                                Soruyu cevapladıktan sonra tekrar dönemezsiniz. Doğruluğundan emin olduktan sonra ilerleyin.
                            </StyledListGroupItem>
                            <StyledListGroupItem  >
                                Her sorunun 1 doğru cevabı vardır. İki şık seçemezsiniz.
                            </StyledListGroupItem>
                            <StyledListGroupItem>
                                Sınav süresi tamamlandıktan sonra çoktan seçmeli sınavların sonucu yayınlanacaktır.
                            </StyledListGroupItem>
                            <StyledListGroupItem  >
                                Yazılı yani açık uçlu sorulardan oluşan sınavların sonucu en geç 1 hafta içinde açıklanacaktır.
                            </StyledListGroupItem>
                            <StyledListGroupItem >
                                Kopya çektiği sistem tarafından tespit edilen kişiler KUZEM kurslarından ömür boyu uzaklaştırılacaktır.
                            </StyledListGroupItem>
                            <StyledListGroupItem  >
                                Her branşın geçme notu 60’tır. Bu notun altında kalan kursiyerler belge alamayacaktır.
                            </StyledListGroupItem>
                            <StyledListGroupItem  >
                                Vize sınavının %40’ı, final sınavının %60’ı alınarak geçme notu belirlenecektir.
                            </StyledListGroupItem>
                        </ListGroup>
                        {/*      -	Sınav süresi KUZEM tarafından belirlenmiştir.
                        -	Sınava sadece 1 kez katılma hakkınız vardır.
                        -	Sınav süresi tamamlandıktan sonra herhangi bir işlem yapılamamaktadır.
                        -	Soruyu cevapladıktan sonra tekrar dönemezsiniz. Doğruluğundan emin olduktan sonra ilerleyin.
                        -	Her sorunun 1 doğru cevabı vardır. İki şık seçemezsiniz.
                        -	Sınav süresi tamamlandıktan sonra çoktan seçmeli sınavların sonucu yayınlanacaktır.
                        -	Yazılı yani açık uçlu sorulardan oluşan sınavların sonucu en geç 1 hafta içinde açıklanacaktır.
                        -	Kopya çektiği sistem tarafından tespit edilen kişiler KUZEM kurslarından ömür boyu uzaklaştırılacaktır.
                        -	Her branşın geçme notu 60’tır. Bu notun altında kalan kursiyerler belge alamayacaktır.
                        -	Vize sınavının %40’ı, final sınavının %60’ı alınarak geçme notu belirlenecektir. */}
                    </div>
                    <h3 className='exam_test_content_description' style={{ overflow: "hidden", textTransform: "capitalize" }} >
                        {detailExam?.description}
                    </h3>


                    <div className='exam_test_confirm_wrapper' >
                        <div className='exam_test_conn' >
                            <h5>
                                Sınav süresi {detailExam?.formal == "Ön Sınav" ? 25 : minuteDiff} dakikadır
                            </h5>
                            <div className='exam_test_btn_container' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <input checked={checked} onChange={(e) => {
                                        setChecked(e.target.checked)
                                    }} type="checkbox" style={{ transform: "scale(1.7)", accentColor: "green" }} />
                                    <p style={{ marginLeft: "15px", fontSize: "16px" }} >
                                        Onaylıyorum
                                    </p>
                                </div>
                                <AntButton type='primary' disabled={!checked} className='start_exam_btn ' onClick={() => {
                                    startExam()
                                }}>
                                    <p className='btn-responsive' >Sınava Başla</p>
                                </AntButton>
                            </div>
                        </div>
                    </div>
                </StyledContentWrap>
            )
        }
        else {
            return (
                <ExamQuestions />
            )
        }
    }

    const examExistControl = async () => {
        try {

            setLoading(true)
            const response = await studentGetByExamIdApi(id)
            console.log("resxxxpxpxp", response.data)
           /*  if (response.data.index !== 1) {
                setStepExam(1)
            }
            if (!response.data.items) {
                navigate("/panel/sinavlarim")
                toast.error("Sınavı zaten tamamladınız", {
                    autoClose: 2500
                })
            } */
        }
        catch (err) {
            navigate(-1)
        }
    }

    const getExamDetailProcess = async () => {
        try {
            setLoading(true)
            const response = await getExamDetailByIdApi(id)

            setDetailExam(response.data)
        }
        catch (err) {
            navigate("/panel/sinavlarim")
        }
        finally {
            setLoading(false)
        }
    }

    const minuteDiff = useMemo(() => {

        if (detailExam?.formal == "Ön Sınav") {
            return getMinuteDifference()
        }
        return getMinuteDifference(detailExam?.startTime, detailExam?.endTime)
    }, [detailExam])

    const startExam = async () => {
        const jwtToken = localStorage.getItem("authToken")
        const endTimeMinutes = getMinuteDifference(new Date().toUTCString(), detailExam?.endTime)
        const jwtControl = isTimeBeforeJwtExpiration(jwtToken, endTimeMinutes)

        /*  if (!jwtControl) {
 
             Modal.confirm({
                 title: "Sınava başlamak için tekrar giriş ekranına yönlendirileceksiniz",
                 cancelText: "İptal",
                 okText: "Giriş ekranına git",
                 onOk: () => {
                     dispatch({
                         type: "LOGOUT"
                     })
                     navigate(`/login?returnPage=/panel/sinav/${id}`)
                     return
                 }
             })
         } */
        /*  await handle.enter() */
        setStepExam(1)
    }


    useEffect(() => {
        const callReq = async () => {
            await examExistControl()
            await getExamDetailProcess()
        }
        callReq()
    }, [])



    if (loading) {
        return (
            <LoaderContainer >
                <LoadingElement color={"green"} />
            </LoaderContainer>


        )
    }

    return (
        <ExamContext.Provider value={{
            detailExam: detailExam,
            handle: handle
        }}  >


            <div className="exam_test_container">
                <header className='exam_test_header' >
                    <h3 className='exam_test_name' >
                        {detailExam?.name}
                    </h3>

                    {
                        stepExam == 0 ? (
                            <AntButton type='primary' onClick={() => {
                                navigate("/panel/sinavlarim")
                            }}   >
                                Panele Dön
                            </AntButton>
                        ) : (
                            <FullName className='fullname-student'>
                                {context?.UserAndLastname}
                            </FullName>
                        )
                    }

                </header>
                <section className='exam_test_content' >
                    {
                        ExamStepMatch()
                    }
                </section>
                {
                    stepExam == 0 && (
                        <footer className='exam_test_footer' >
                            <div className='exam_test_footer_wrapper' >
                                <h3 className='exam_test_footer_title' >
                                    Kuzem
                                </h3>
                                <span>{new Date().getFullYear()}</span>
                            </div>

                        </footer>
                    )
                }

            </div>

        </ExamContext.Provider>


    );
}

export default ExamDashboard



const LoaderContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FullName = styled.p`
    color: white;
    font-size: 16px;
`


const StyledContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const StyledListGroupItem = styled(ListGroupItem)`
    font-size: 12px;
    padding: 5px;
`