import { Button, Input, Modal, Select, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { FaEdit, FaFile, FaImage, FaPlus, FaTrash } from 'react-icons/fa'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import { Label } from 'reactstrap'
import { toast } from 'react-toastify'

const EKitapDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        editMode: false,
        data: {
            title: "",
            category: "",
            id: ""
        }
    })
    const [categorieList, setCategorieList] = useState([])
    const fileRef = useRef()
    const [topicFile, setTopicFile] = useState({
        name: "",
        file: null
    })
    const [ebookList, setEbookList] = useState([])




    const getAllCategories = async () => {
        try {
            const response = await axiosInstance.post("/categories/getall", {
                page: 0,
                pageSize: 1
            })

            const responsev2 = await axiosInstance.post("/categories/getall", {
                page: 0,
                pageSize: response.data.count
            })
            setCategorieList(responsev2.data.items)
        }
        catch (err) {

        }
    }


    const handleImage = (e) => {
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ];
        const file = e.target.files[0]
        if (allowedTypes.includes(file?.type)) {
            setTopicFile({
                name: file?.name,
                file: file
            })
        }
        else {
            toast.error("Bu formatta dosya tipi uygun değil", {
                position: "top-center",
                autoClose: 2000
            })
        }
    }

    const PreviewTopicImg = () => {
        

        if (topicFile.file instanceof File) {
            window.open(URL.createObjectURL(topicFile.file), "_blank")
        }
        else {
            window.open(topicFile.file, "_blank")
        }
        /*  if (topicFile.file instanceof File) {
             window.open(topicFile.file, "_blank")
         }
         else {
             window.open(URL.createObjectURL(topicFile.file), "_blank")
         } */

    }

    const AddEbook = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("formFile", topicFile.file)
            const response = await axiosInstance.post(`/ElectronicBooks/Add?categoryId=${modalInfo.data.category}&name=${modalInfo.data.title}`, formData)

            toast.success("İçerik eklendi", {
                position: "top-center",
                autoClose: 2500
            })
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getAllEbooks = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/electronicbooks/getall", {
                page: 0,
                pageSize: 1
            })
            const responsev2 = await axiosInstance.post("/electronicbooks/getall", {
                page: 0,
                pageSize: response.data.count
            })

            setEbookList(responsev2.data.items)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const updateEbook = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            if (topicFile.file instanceof File) {
                formData.append("formFile", topicFile.file)
                const response = await axiosInstance.put(`/electronicbooks/update?categoryId=${modalInfo.data.category}&name=${modalInfo.data.title}&id=${modalInfo.data.id}`, formData)
                await getAllEbooks()

                setModalInfo({
                    editMode: false,
                    visible: false,
                    data: {
                        category: "",
                        id: "",
                        title: ""
                    }
                })
                toast.success("e-kitap güncellendi", {
                    autoClose: 2000,
                    position: "top-center"
                })
            }
            else {
                const response = await axiosInstance.put(`/electronicbooks/update?categoryId=${modalInfo.data.category}&name=${modalInfo.data.title}&id=${modalInfo.data.id}`)
                await getAllEbooks()
                setModalInfo({
                    editMode: false,
                    visible: false,
                    data: {
                        category: "",
                        id: "",
                        title: ""
                    }
                })
                toast.success("e-kitap güncellendi", {
                    autoClose: 2000,
                    position: "top-center"
                })
            }


        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        getAllCategories()
        getAllEbooks()
    }, [])


    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <Button type='primary' style={{ display: "flex", alignItems: "center", columnGap: "10px" }} onClick={() => {
                        setModalInfo({
                            visible: true,
                            data: {
                                category: "",
                                title: ""
                            }
                        })
                    }} >
                        Ekle <FaPlus />
                    </Button>
                </StyledHeader>
                <StyledContentContainer>
                    <Table
                        style={{
                            width: "100%"
                        }}
                        dataSource={ebookList}
                        scroll={{
                            x: 600
                        }}
                        columns={[
                            {
                                title: "Id",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.id}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Başlık",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.name}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Kategori",
                                render: (row, record) => {


                                    return (
                                        <p>
                                            {record?.category?.name}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "işlemler",
                                render: (row, record) => {

                                    return (
                                        <div style={{ display: "flex", columnGap: "10px" }} >
                                            <Button type='primary' style={{ display: "flex", alignItems: "center" }} onClick={() => {
                                                setModalInfo({
                                                    visible: true,
                                                    editMode: true,
                                                    data: {
                                                        category: record?.categoryId,
                                                        title: record?.name,
                                                        id: record?.id
                                                    }
                                                })
                                                setTopicFile({
                                                    file: record?.fileUrl,
                                                    name: record?.fileUrl
                                                })
                                            }} >
                                                <FaEdit />

                                            </Button>

                                            <Button danger type='primary' onClick={() => {
                                                Modal.confirm({
                                                    title: "Silmek istediğinize emin misiniz ?",
                                                    okText: "Eminim",
                                                    cancelText: "iptal",
                                                    onOk: async () => {
                                                        try {
                                                            await axiosInstance.delete(`/electronicbooks/delete/${record?.id}`)

                                                            getAllEbooks()

                                                            toast.success("Silme işlemi tamamlandı", {
                                                                position: "top-center",
                                                                autoClose: 2000
                                                            })
                                                        }
                                                        catch (err) {

                                                        }
                                                    }
                                                })
                                            }} >
                                                <FaTrash />
                                            </Button>
                                        </div>
                                    )
                                }
                            }
                        ]}
                    />
                </StyledContentContainer>
            </StyledContainer>
            <Modal
                onCancel={() => {
                    setModalInfo({
                        visible: false,
                        editMode: false,
                        data: {
                            title: "",
                            category: ""
                        }
                    })
                    setTopicFile({
                        name: "",
                        file: null
                    })
                }}
                open={modalInfo.visible}
                title={modalInfo.editMode ? "Güncelle" : "İçerik ekle"}
                footer={
                    () => {
                        return (
                            <StyledFooter>
                                <Button onClick={() => {
                                    fileRef.current.click()
                                }} type='primary' style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                                    Dosya <FaImage />
                                </Button>
                                <div style={{ display: "flex", columnGap: "10px" }} >
                                    <Button danger type='primary' onClick={() => {
                                        setModalInfo({
                                            visible: false,
                                            editMode: false,
                                            data: {
                                                title: "",
                                                category: ""
                                            }
                                        })
                                        setTopicFile({
                                            file: null,
                                            name: ""
                                        })
                                    }} >
                                        Kapat
                                    </Button>
                                    <Button onClick={() => {
                                        if (modalInfo.editMode) {
                                            updateEbook()
                                        }
                                        else {
                                            AddEbook()
                                        }

                                    }} loading={loading} type='primary' disabled={modalInfo.data?.description == "" || modalInfo.data?.title == "" || !topicFile.file}  >
                                        {loading ? "Yükleniyor" : "Kaydet"}
                                    </Button>
                                </div>
                            </StyledFooter>
                        )
                    }
                }
            >
                <StyledModalContent>
                    <div style={{ display: "flex", flexDirection: "column", rowGap: "0px" }} >
                        <Label>
                            Başlık
                        </Label>
                        <Input placeholder='Başlık' value={modalInfo.data?.title} onChange={(e) => {
                            setModalInfo({
                                ...modalInfo,
                                data: {
                                    ...modalInfo.data,
                                    title: e.target.value
                                }
                            })
                        }} />
                    </div>
                    <input type="file" style={{ display: "none" }} ref={fileRef} onChange={handleImage} />
                    <div style={{ display: "flex", flexDirection: "column", rowGap: "0px" }} >
                        <Label>
                            Kategori
                        </Label>
                        <Select
                            onChange={(e) => {
                                setModalInfo({
                                    ...modalInfo,
                                    data: {
                                        ...modalInfo.data,
                                        category: e
                                    }
                                })
                            }}
                            value={modalInfo.data?.category}
                            placeholder="Kategori"
                            options={categorieList.map(item => {
                                return {
                                    "label": item.name,
                                    "value": item.id
                                }
                            })}

                        />
                    </div>

                    {
                        topicFile.file ? (
                            <StyledModalImageContent style={{ cursor: "pointer" }} onClick={() => {
                                PreviewTopicImg()
                            }}  >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                                        <FaFile /> {topicFile.name.substring(0, 30)}...
                                    </div>
                                    <Button danger type='primary' size='small' style={{ display: "flex", alignItems: "center" }} onClick={(e) => {
                                        e.stopPropagation()
                                        setTopicFile({
                                            file: null,
                                            name: ""
                                        })
                                    }} >
                                        <FaTrash />
                                    </Button>
                                </div>

                            </StyledModalImageContent>
                        ) : (
                            <StyledModalImageContent>
                                <p>
                                    Henüz Fotoğraf seçilmedi
                                </p>
                            </StyledModalImageContent>
                        )
                    }
                </StyledModalContent>

            </Modal>
        </>
    )
}

export default EKitapDashboard


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

const StyledHeader = styled.div`
    display: flex;
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
`

const StyledContentContainer = styled.div`
    display: flex;
    margin-top: 20px;

`
const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap:15px;
    margin: 20px 0 30px 0;
`

const StyledModalImageContent = styled.div`
    display: flex;
    border: 1px dashed gray;
    padding: 10px;
    border-radius: 7px;
    align-items: center;
    column-gap: 10px;
`