import React from "react";

const LoadingSpinner = () => {
 return (
  <div className="loading-container">
   <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    className="loading-spinner"
   >
    <circle
     cx="50"
     cy="50"
     r="45"
     fill="none"
     stroke="#E8F1FF"
     strokeWidth="8"
    />
    <circle
     cx="50"
     cy="50"
     r="45"
     fill="none"
     stroke="#1A73E8"
     strokeWidth="8"
     strokeDasharray="283"
     strokeDashoffset="283"
     className="loading-circle"
    />
   </svg>
  </div>
 );
};

export default LoadingSpinner;
