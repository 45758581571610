import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/user";

const Navdata = () => {
 const history = useNavigate();
 const [isApps, setIsApps] = useState(false);
 const [isAppointments, setIsAppointments] = useState(false);
 const [isExam, setExam] = useState(false);
 const [editPages, setEditPages] = useState(false);
 const [isMyCourse, setMyCourse] = useState(false);
 const [iscurrentState, setIscurrentState] = useState("Dashboard");
 const [isAdminCourse, setIsAdminCourse] = useState(false);
 const [messageMenu, setMessageMenu] = useState(false);
 function updateIconSidebar(e) {
  if (e && e.target && e.target.getAttribute("subitems")) {
   const ul = document.getElementById("two-column-menu");
   const iconItems = ul.querySelectorAll(".nav-icon.active");
   let activeIconItems = [...iconItems];
   activeIconItems.forEach((item) => {
    item.classList.remove("active");
    var id = item.getAttribute("subitems");
    if (document.getElementById(id))
     document.getElementById(id).classList.remove("show");
   });
  }
 }

 const menuItems = [
  {
   id: "Anasayfa",
   label: "Anasayfa",
   icon: "ri-home-4-line",
   link: "/panel/anasayfa",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin", "Admin", "Öğrenci", "Öğretmen"],
  },
  {
   id: "Eğitmenler",
   label: "Eğitmenler",
   icon: "ri-user-4-line",
   link: "/panel/egitmen",
   click: function (e) {
    e.preventDefault();
    document.body.classList.remove("vertical-sidebar-enable");
   },
   role: ["SüperAdmin", "Admin"],
  },
  {
   id: "branslar",
   label: "Branslar",
   icon: "ri-git-branch-fill",
   link: "/panel/branslar",
   click: function (e) {
    e.preventDefault();
    setIscurrentState("Branslar");
   },
   role: ["SüperAdmin", "Admin"],
  },
  {
   id: "mesaj",
   label: "Mesajlar",
   icon: "ri-message-2-line",
   link: "/panel/ogrenci/mesaj",
   click: function (e) {
    e.preventDefault();
    /*           setIscurrentState("Branslar"); */
   },
   role: ["Öğrenci"],
  },
  {
   id: "mesajlar",
   label: "Mesajlar",
   icon: "ri-message-2-line",
   link: "/panel/egitmen/mesaj",
   parentId: "kurslar",
   role: ["Öğretmen"],
  },
  {
   id: "kategori",
   label: "Kategoriler",
   icon: "ri-menu-5-line",
   link: "/panel/kategori",
   role: ["SüperAdmin", "Admin"],
  },
  {
   id: "keşfet",
   label: "Kayıt Ekranı",
   icon: "ri-git-repository-line",
   link: "/panel/kurslar",
   role: ["SüperAdmin", "Admin", "Öğrenci"],
   click: function (e) {
    e.preventDefault();
    setIsApps(!isApps);
    setIscurrentState("Apps");
    updateIconSidebar(e);
   },
   stateVariables: isApps,
   subItems: [
    {
     id: "cevrimicikurslar",
     label: "Canlı Eğitimler",
     icon: "ri-menu-line",
     link: "/panel/cevrimici",
     parentId: "kurslar",
     role: ["SüperAdmin", "Admin", "Öğrenci"],
    },
    {
     id: "cevrimdisikurslar",
     label: "Video Eğitimler",
     icon: "ri-menu-line",
     link: "/panel/cevrimdisi",
     parentId: "kurslar",
     role: ["SüperAdmin", "Admin", "Öğrenci"],
    },
   ],
  },
  {
   id: "Kurslar",
   label: "Kurs Detay",
   icon: "ri-git-repository-line",
   role: ["SüperAdmin"],
   click: function (e) {
    e.preventDefault();
    setIsAdminCourse(!isAdminCourse);
    setIscurrentState("Apps");
    updateIconSidebar(e);
   },
   stateVariables: isAdminCourse,
   subItems: [
    {
     id: "onlinekursderay",
     label: "Canlı Eğitim Detay",
     icon: "ri-menu-line",
     link: "/panel/cevrimicidetay",
     parentId: "kurslar",
     role: ["SüperAdmin"],
    },
    {
     id: "videokursderay",
     label: "Video Eğitim Detay",
     icon: "ri-menu-line",
     link: "/panel/videoegitimdetay",
     parentId: "kurslar",
     role: ["SüperAdmin"],
    },
    // {
    //  id: "canliegitimdate",
    //  label: "Canlı Yayın Listesi",
    //  icon: "ri-menu-line",
    //  link: "/panel/canliyayin",
    //  parentId: "kurslar",
    //  role: ["SüperAdmin"],
    // },
   ],
  },
  {
   id: "kurslarım",
   label: "Kurslarım",
   icon: "ri-git-repository-line",
   link: "/panel/kurslar",
   role: ["Öğrenci", "Öğretmen"],
   click: function (e) {
    e.preventDefault();
    setMyCourse(!isMyCourse);
    setIscurrentState("Apps");
    updateIconSidebar(e);
   },
   stateVariables: isMyCourse,
   subItems: [
    {
     id: "kurslarim",
     label: "Kurslarım",
     icon: "ri-menu-line",
     link: "/panel/kurslarim",
     parentId: "kurslar",
     role: ["Öğrenci"],
    },
    {
     id: "sorumlukurslar",
     label: "Sorumlu Kurslarım",
     icon: "ri-line-chart-line",
     link: "/panel/sorumlukurslar",
     parentId: "kurslar",
     role: ["Öğretmen"],
    },
    {
     id: "canliegitim",
     label: "Canlı eğitim",
     icon: "ri-line-chart-line",
     link: "/panel/egitmen/canliders",
     parentId: "kurslar",
     role: ["Öğretmen"],
    },
    {
     id: "ogrencilerim",
     label: "Öğrencilerim",
     icon: "ri-line-chart-line",
     link: "/panel/egitmen/ogrencilerim",
     parentId: "kurslar",
     role: ["Öğretmen"],
    },
    {
     id: "canliegitim",
     label: "Canlı eğitim",
     icon: "ri-line-chart-line",
     link: "/panel/ogrenci/canliders",
     parentId: "kurslar",
     role: ["Öğrenci"],
    },
   ],
  },
  {
   id: "kullanıcılar",
   label: "Kullanıcılar",
   icon: "ri-user-line",
   link: "/panel/kullanıcılar",
   click: function (e) {
    e.preventDefault();
    setIsAppointments(!isAppointments);
    setIscurrentState("Appointments");
    updateIconSidebar(e);
   },
   role: ["SüperAdmin", "Admin"],
  },
  {
   id: "anket",
   label: "Anket",
   icon: "ri-survey-line",
   link: "/panel/anket",
   click: function (e) {
    e.preventDefault();
   },
   role: ["Öğretmen", "Admin", "SüperAdmin"],
  },
  {
   id: "anketlerim",
   label: "Anketlerim",
   icon: "ri-survey-line",
   link: "/panel/ogrenci/anket",
   click: function (e) {
    e.preventDefault();
   },
   role: ["Öğrenci"],
  },
  {
   id: "sinavlar",
   label: "Sınav",
   icon: "ri-a-b",
   link: "/panel/sinav",
   click: function (e) {
    e.preventDefault();
    setExam(!isExam);
    setIscurrentState("Apps");
    updateIconSidebar(e);
   },
   role: ["Öğrenci"],
   stateVariables: isExam,
   subItems: [
    {
     id: "sinavlar",
     label: "Sınavlarım",
     icon: "ri-menu-line",
     link: "/panel/sinavlarim",
     parentId: "sinavlar",
     role: ["Öğrenci"],
    },
    {
     id: "sinavsonuclarim",
     label: "Sonuçlar",
     icon: "ri-menu-line",
     link: "/panel/sinav/sonuclarim",
     parentId: "sinavsonuclarim",
     role: ["Öğrenci"],
    },
   ],
  },
  {
   id: "sinavlar",
   label: "Sınavlar",
   icon: "ri-a-b",
   link: "/panel/sinavlar",
   click: function (e) {
    e.preventDefault();
   },
   role: ["Öğretmen", "Admin", "SüperAdmin"],
  },
  {
   id: "soruhavuzu",
   label: "Soru Havuzu",
   icon: "ri-a-b",
   link: "/panel/soruhavuzu",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin"],
  },
  {
   id: "soruhavuzu",
   label: "Soru Havuzu",
   icon: "ri-a-b",
   link: "/panel/egitmen/soruhavuzu",
   click: function (e) {
    e.preventDefault();
   },
   role: ["Öğretmen"],
  },
  {
   id: "sertifika",
   label: "Sertifikalar",
   icon: "ri-file-list-3-line",
   link: "/panel/sertifika",
   click: function (e) {
    e.preventDefault();
   },
   role: ["Öğrenci"],
  },
  {
   id: "sayfalar",
   label: "Sayfalar",
   icon: "ri-file-list-3-line",
   link: "",
   click: function (e) {
    e.preventDefault();
    setEditPages(!editPages);
    setIscurrentState("Apps");
    updateIconSidebar(e);
   },
   role: ["SüperAdmin"],
   stateVariables: editPages,
   subItems: [
    {
     id: "blog",
     label: "Blog",
     icon: "ri-menu-line",
     link: "/panel/blog",
     parentId: "sayfalar",
     role: ["SüperAdmin"],
    },
    {
     id: "güncel",
     label: "Güncel",
     icon: "ri-menu-line",
     link: "/panel/guncel",
     parentId: "sayfalar",
     role: ["SüperAdmin"],
    },
    {
     id: "duyurular",
     label: "Duyurular",
     icon: "ri-menu-line",
     link: "/panel/duyurular",
     parentId: "sayfalar",
     role: ["SüperAdmin"],
    },
    {
     id: "ekitap",
     label: "E-Kitap",
     icon: "ri-menu-line",
     link: "/panel/ekitap",
     parentId: "sayfalar",
     role: ["SüperAdmin"],
    },
    {
     id: "recommendedcourse",
     label: "Önerilen Kurslar",
     icon: "ri-menu-line",
     link: "/panel/onerilenkurslar",
     parentId: "sayfalar",
     role: ["SüperAdmin"],
    },
   ],
  },
  {
   id: "istatistik",
   label: "İstatistikler",
   icon: "ri-numbers-line",
   link: "/panel/istatistik",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin"],
  },
  {
   id: "toplanti",
   label: "Toplantı",
   icon: "ri-video-chat-line",
   link: "/panel/toplanti",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin", "Öğretmen"],
  },
  {
   id: "sms",
   label: "Sms",
   icon: "ri-message-3-fill",
   link: "/panel/sms/ogrenci",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin"],
  },
  {
   id: "mail",
   label: "Mail",
   icon: "ri-mail-fill",
   link: "/panel/mail",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin"],
  },
  {
   id: "drive",
   label: "Drive",
   icon: "ri-cloud-fill",
   link: "/panel/drive",
   click: function (e) {
    e.preventDefault();
   },
   role: ["SüperAdmin"],
  },
  /* {
            id: "puantaj",
            label: "Puantaj",
            icon: "ri-money-dollar-box-line",
            link: "/panel/puantaj",
            click: function (e) {
                e.preventDefault()
            },
            role: ["SüperAdmin"]
        }, */
  {
   id: "devamsızlık",
   label: "Devamsızlık Takibi",
   icon: "ri-calendar-line",
   link: "/panel/devamsizlik",
   click: function (e) {
    e.preventDefault();
   },
   role: ["Öğrenci"],
  },
 ];

 return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
