import React, { useContext, useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../Common/TablePageContainer'
import { Button, Select, Table } from "antd"
import styled from 'styled-components'
import { UserContext } from '../../context/user'
import { axiosInstance } from '../../api/axiosInstance'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import EgitmenSoruEkle from './EgitmenSoruEkle'
import { uniqueId } from 'lodash'
import { FaEye } from 'react-icons/fa'
import EgitmenSoruGuncelle from './EgitmenSoruGuncelle'

const EgitmenSoruHavuzu = () => {
    const [context, dispatch] = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [branchLiset, setBranchList] = useState([])
    const [selectedBranch, setSelectedBranch] = useState("Hepsi")
    const [selectedStatus, setSelectedStatus] = useState("Bekliyor")
    const [questionList, setQuestionList] = useState([])
    const [levelType, setLevelType] = useState("Hepsi")
    const [editMode, setEditMode] = useState({
        mode: true,
        data: []
    })
    const defautlPageSize = 10;
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        data: null
    })
    const [addQuestionData, setAddQuestionData] = useState({
        questionContent: "",
        point: 0,
        answers: [
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "a"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "b"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "c"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "d"
            },
            {
                "content": "",
                "id": uniqueId(),
                "correct": false,
                "option": "e"
            }
        ],
        branchId: "",
        level: "Kolay"
    })
    const teacherId = useMemo(() => {
        return context?.userId
    }, [context])


    const getQuestionList = async (branchId, branchQuestionStatus, currentPage = 0) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/BranchQuestions/GetAllByBranchId", {
                branchId: branchId,
                branchQuestionStatus,
                pageRequest: {
                    page: currentPage,
                    pageSize: defautlPageSize
                }
            })
            setQuestionList(response.data.items)
            setTotalCount(response.data.count)
        }
        catch (err) {


        }
        finally {
            setLoading(false)

        }
    }


    const getQuestinDynamic = async (status = "Bekliyor", branchId = "Hepsi", pageIndex = 0, level = "Hepsi") => {
        try {
            setLoading(true)
            const baseObj = {
                "dynamic": {
                    "sort": [
                        {
                            "field": "id",
                            "dir": "asc"
                        }
                    ],
                    "filter": {
                        "field": "Status",
                        "operator": "eq",
                        "value": status,
                        "logic": "and",
                        "filters": [

                        ]
                    }
                },
                "pageRequest": {
                    "page": pageIndex,
                    "pageSize": 10
                }
            }
            if (branchId != "Hepsi") {
                baseObj.dynamic.filter.filters.push({
                    "field": "branchId",
                    "operator": "eq",
                    "value": branchId,
                    "logic": "and"
                })
            }
            if (level != "Hepsi") {
                baseObj.dynamic.filter.filters.push({
                    "field": "level",
                    "operator": "eq",
                    "value": level,
                    "logic": "and"
                })
            }

            const response = await axiosInstance.post("/BranchQuestions/GetAllByDynamic", baseObj)
            setTotalCount(response.data.count)
            setQuestionList(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }





    const getTeacherBranches = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post(`/TeacherBranches/GetAllByUserId?userId=${context?.userId}`, {
                page: 0,
                pageSize: 20
            })
            setBranchList(response.data.items)
            setSelectedBranch(response.data.items[0]?.branch?.id)

            getQuestionList(response.data.items[0]?.branch?.id, 0)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }




    useEffect(() => {
        getTeacherBranches()
        getQuestinDynamic("Bekliyor", "Hepsi", 0, "Hepsi")
    }, [])

    return (
        <div className='page-content'>
            <TablePageContainer header={"Soru Havuzu"} visible={false}>
                <StyledHeader>
                    <FilterContainer>
                        <StatusSelect
                            value={selectedStatus}
                            onChange={(e) => {
                                setSelectedStatus(e)
                                getQuestinDynamic(e, selectedBranch, 0, levelType)
                                setCurrentPage(1)
                            }}
                            placeholder="Durum"
                            options={[
                                {
                                    "label": "Bekleyen",
                                    "value": "Bekliyor"
                                },
                                {
                                    "label": "Reddedilen",
                                    "value": "Reddedildi"
                                },
                                {
                                    "label": "Onaylanan",
                                    "value": "Onaylandı"
                                }
                            ]}

                        />
                        <BranchSelect
                            placeholder="Branş Seçiniz"
                            value={selectedBranch}
                            onChange={(e) => {
                                setSelectedBranch(e)
                                getQuestinDynamic(selectedStatus, e, 0, levelType)
                                setCurrentPage(1)
                            }}
                            options={[
                                {
                                    label: "Hepsi",
                                    value: "Hepsi"
                                },
                                ...branchLiset.map(item => {
                                    return {
                                        "label": item?.branch.name,
                                        "value": item?.branch?.id
                                    }
                                })
                            ]}

                        />
                        <BranchSelect
                            placeholder="Zorluk Seçiniz"
                            value={levelType}
                            onChange={(e) => {
                                setLevelType(e)
                                getQuestinDynamic(selectedStatus, selectedBranch, 0, e)
                                setCurrentPage(1)
                            }}
                            options={[
                                {
                                    label: "Hepsi",
                                    value: "Hepsi"
                                },
                                {
                                    label: "Kolay",
                                    value: "Kolay"
                                },
                                {
                                    label: "Orta",
                                    value: "Orta"
                                },
                                {
                                    label: "Zor",
                                    value: "Zor"
                                }
                            ]}

                        />
                    </FilterContainer>
                    <Button type='primary' onClick={() => {
                        setModalInfo({
                            visible: true,
                            data: null
                        })
                    }} >
                        Soru Ekle
                    </Button>
                </StyledHeader>
                <Table
                    loading={loading}
                    scroll={{
                        x: 700
                    }}
                    dataSource={questionList}
                    pagination={{
                        pageSize: defautlPageSize,
                        showSizeChanger: false,
                        total: totalCount,
                        current: currentPage,
                        onChange: (e) => {
                            setCurrentPage(e)
                            getQuestinDynamic(selectedStatus, selectedBranch, e - 1, levelType)
                        }
                    }}
                    columns={[
                        {
                            "title": "id",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.id}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "isim",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.question}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Toplam Kullanım",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.totalUsage}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Zorluk",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.level == "0" ? "Seçilmedi" : record?.level}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Puan",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.point}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Branş",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.branch?.name}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Sorular",
                            render: (row, record) => {

                                return (
                                    <Button type='primary' onClick={() => {
                                        setEditMode({
                                            visible: true,
                                            data: record
                                        })

                                    }} >
                                        <FaEye />
                                    </Button>
                                )
                            }
                        },
                        {
                            "title": "Durum",
                            render: (row, record) => {
                                return (
                                    <p style={{ padding: "5px 8px", backgroundColor: record?.status == "Reddedildi" ? "red" : record?.status == "Onaylandı" ? "blue" : "orange", borderRadius: "5px", width: "max-content", color: "white" }}  >
                                        {record.status}
                                    </p>
                                )
                            }
                        },
                    ]}
                />

            </TablePageContainer>

            <EgitmenSoruEkle
                branchList={branchLiset}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                addQuestionData={addQuestionData}
                setAddQuestionData={setAddQuestionData}
                refreshMethod={() => {
                    getQuestionList(selectedBranch, selectedStatus, currentPage - 1)
                }}
            />
            <EgitmenSoruGuncelle refreshData={() => {
                getQuestionList(selectedBranch, selectedStatus, currentPage - 1)
            }} branchList={branchLiset} editMode={editMode} setEditMode={setEditMode}

            />
        </div>
    )
}

export default EgitmenSoruHavuzu

const StyledHeader = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px dashed gray;
 
`

const BranchSelect = styled(Select)`
    width:250px;

`
const StatusSelect = styled(Select)`
width:200px;

`

const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`