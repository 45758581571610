import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
 Dropdown,
 DropdownItem,
 DropdownMenu,
 DropdownToggle,
 Button,
} from "reactstrap";
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import { UserContext } from "../../context/user";

const ProfileDropdown = ({ visiblePanel = false, logoutMethod = null }) => {
 const [state, dispatch] = useContext(UserContext);
 const [isProfileDropdown, setIsProfileDropdown] = useState(false);
 const [profileImage, setProfileImage] = useState(null);
 const toggleProfileDropdown = () => {
  setIsProfileDropdown(!isProfileDropdown);
 };
 const navigate = useNavigate();

 function capitalizeSentenceLocale(sentence, locale = "tr-TR") {
  if (!sentence) return "";
  return sentence
   .split(" ")
   .map((word) => {
    const firstLetter = word.charAt(0).toLocaleUpperCase(locale);
    const rest = word.slice(1).toLocaleLowerCase(locale);
    return firstLetter + rest;
   })
   .join(" ");
 }

 return (
  <React.Fragment>
   <Dropdown
    isOpen={isProfileDropdown}
    toggle={toggleProfileDropdown}
    className=" header-item topbar-user"
   >
    <DropdownToggle tag="button" type="button" className="btn">
     <span className="d-flex align-items-center">
      <span className="text-end ">
       <p style={{ fontSize: "18px" }} className="fw-bold">
        {capitalizeSentenceLocale(state.userAndLastName)}{" "}
       </p>
       <p style={{ fontSize: "10px" }} className="fw-light">
        {state.role}
       </p>
      </span>
      <img
       className="rounded-circle header-profile-user"
       loading="lazy"
       src={profileImage || avatar1}
       alt="Header Avatar"
      />
     </span>
    </DropdownToggle>
    <DropdownMenu className="dropdown-menu-end">
     <h6 className="dropdown-header">Hoş Geldiniz !</h6>
     {visiblePanel ? (
      <DropdownItem className="p-0">
       <Link
        className="dropdown-item"
        to={"/panel/anasayfa"}
        style={{ display: "flex", alignItems: "center", fontSize: "14px" }}
       >
        <HomeOutlined style={{ marginRight: "10px" }} />
        Panel
       </Link>
      </DropdownItem>
     ) : (
      <>
       <DropdownItem className="p-0">
        <Link
         className="dropdown-item"
         to={"/panel/profil"}
         style={{ display: "flex", alignItems: "center", fontSize: "14px" }}
        >
         <UserOutlined style={{ marginRight: "10px" }} />
         Profil
        </Link>
       </DropdownItem>
       <DropdownItem className="p-0">
        <Link
         className="dropdown-item"
         to={"/"}
         style={{ display: "flex", alignItems: "center", fontSize: "14px" }}
        >
         <HomeOutlined style={{ marginRight: "10px" }} />
         KUZEM
        </Link>
       </DropdownItem>
      </>
     )}

     <DropdownItem className="p-0">
      <div
       onClick={() => {
        dispatch({ type: "LOGOUT" });
        if (logoutMethod) {
         logoutMethod();
        }
       }}
       className="dropdown-item"
      >
       <span className="align-middle" data-key="t-logout">
        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
        Çıkış Yap
       </span>
      </div>
     </DropdownItem>
    </DropdownMenu>
   </Dropdown>
  </React.Fragment>
 );
};

export default ProfileDropdown;
