import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { Button } from 'antd';
import axios from 'axios';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import styled from 'styled-components';



const QuillEditor = ({ editorHtml, setEditorHtml }) => {
    /* const [state, setState] = useState({ value: null }); */
    const handleChange = value => {
        setEditorHtml({ value });
    };

    return (
        <div>
            <EditorToolbar />
            <StyledEditor

                theme="snow"
                value={editorHtml.value}
                onChange={handleChange}
                placeholder={"Bir şeyler yaz"}
                modules={modules}
                formats={formats}
            />
            {/* <ReactQuill
                theme="snow"
                ref={quillRef}
                onChange={handleChange}
                modules={QuillEditor.modules}
                bounds={'#root'}
            />
            <div>


                <div className='ql-snow'>
                    <div className='ql-editor' dangerouslySetInnerHTML={{ __html: editorHtml }}>

                    </div>
                </div>
            </div> */}
        </div>
    )
}


export default QuillEditor

const StyledEditor = styled(ReactQuill)`

    .ql-container {
        height: 300px;
}

`