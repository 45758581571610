import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import React, { useEffect } from "react";
import VerticalLayout from "./VerticalLayouts";
import KuzemBlueLogo from "../assets/images/KuzemBlueLogo.png";

const Sidebar = ({ layoutType }) => {
 useEffect(() => {
  var verticalOverlay = document.getElementsByClassName("vertical-overlay");
  if (verticalOverlay) {
   verticalOverlay[0].addEventListener("click", function () {
    document.body.classList.remove("vertical-sidebar-enable");
   });
  }
 });

 const addEventListenerOnSmHoverMenu = () => {
  if (
   document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
  ) {
   document.documentElement.setAttribute(
    "data-sidebar-size",
    "sm-hover-active"
   );
  } else if (
   document.documentElement.getAttribute("data-sidebar-size") ===
   "sm-hover-active"
  ) {
   document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
  } else {
   document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
  }
 };

 return (
  <React.Fragment>
   <div
    className="app-menu navbar-menu"
    style={{
     transition: ".5s all",
    }}
   >
    <div className="navbar-brand-box">
     {/* <Link to="/" className="logo logo-light">
      <span className="logo-sm">
       <img src={logoSm} alt="" height="32" />
      </span>
     </Link> */}

     <Link to="/" className="logo logo-dark">
      <span className="logo-sm">KUZEM</span>
     </Link>
     <button
      onClick={addEventListenerOnSmHoverMenu}
      type="button"
      className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
      id="vertical-hover"
     >
      <i className="ri-record-circle-line"></i>
     </button>
    </div>
    <React.Fragment>
     <SimpleBar id="scrollbar" className="h-100">
      <img
       src={KuzemBlueLogo}
       alt=""
       style={{
        objectFit: "contain",
        width: "100%",
        objectPosition: "center",
        padding: "5% 10%",
       }}
      />
      <Container fluid>
       <div id="two-column-menu"></div>
       <ul className="navbar-nav" id="navbar-nav">
        <VerticalLayout layoutType={layoutType} />
       </ul>
      </Container>
     </SimpleBar>
     <div className="sidebar-background"></div>
    </React.Fragment>
   </div>
   <div className="vertical-overlay"></div>
  </React.Fragment>
 );
};

export default Sidebar;
