import { Input } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { FaImages } from 'react-icons/fa'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { Button as AntButton, Modal as AntModal } from "antd"
import { examWrittenAnswerApi } from '../../../api/exam'
import { toast } from 'react-toastify'
const ExamGivePointModal = ({ modalData, setModalData, refreshData }) => {

    const [selectedPhotoModal, setSelectedPhotoModal] = useState({
        visible: false,
        url: ""
    })
    const [mainData, setMainData] = useState()

    const [puanList, setPuanList] = useState([])

    const studentInfo = useMemo(() => {
     
        return modalData?.data?.student
    }, [modalData])

    const studentExamList = useMemo(() => {
        return modalData?.data?.answers
    })


    const pointProcess = () => {
        try {
            let summaryPoint = puanList.reduce((acc, item) => acc += item.point, 0)
            AntModal.confirm({
                title: `${studentInfo.firstName} ${studentInfo.lastName} için verdiğiniz puan ${summaryPoint} onaylıyor musunuz ?  `,
                okText: "Onayla",
                cancelText: "iptal",
                onOk: async () => {
                    try {
   
                        const response = await Promise.all(puanList.map(el => examWrittenAnswerApi({
                            id: el.id,
                            point: el.point
                        })))
                        await refreshData()
                        setModalData({
                            visible: false,
                            data: null
                        })
                        toast.success(`${studentInfo?.firstName} ${studentInfo?.lastName} isimli öğrenci için ${summaryPoint} puan verildi `, {
                            autoClose: 2500,
                            position: "top-center",
                            style: {
                                fontSize: 14,
                                fontWeight: "bold"
                            }
                        })

                    }
                    catch (err) {

                    }
                }
            })
     
        }
        catch (err) {

        }
    }


    useEffect(() => {
      
        setPuanList(modalData?.data?.answers.map(el => {
            return {
                id: el?.examAnswer?.id,
                defaultPoint: el.point,
                point: el.examAnswer?.point
            }
        }))
        setMainData({
            ...modalData?.data?.student
        })
    }, [modalData])

    return (
        <>
            <Modal isOpen={modalData.visible} size='lg' >
                <StyledModalHeader>
                    <p className='exam_give_modal_username' >
                        {`${studentInfo?.firstName} ${studentInfo?.lastName} `}
                    </p>
                    <AntButton type='primary' danger color='danger' onClick={() => {
                        setModalData({
                            visible: false,
                            data: null
                        })
                        setPuanList([])
                    }} >
                        Kapat
                    </AntButton>
                </StyledModalHeader>
                <StyledModalBody>
                    <StyledGiveQuestionWrapper>
                        {
                            studentExamList?.map((item, index) => {
                        
                                return (
                                    <StyledGivePointModalQuestionContainer key={`${index}`} >
                                        <Input.TextArea value={item?.question} style={{ resize: "none" }} disabled={true} styles={{
                                            textarea: {
                                                backgroundColor: "#eee",
                                                color: "black"
                                            }
                                        }} />
                                        <ExamGivePointContainer>
                                            <p className='give_question_item_point' >
                                                Puan : {item.point}
                                            </p>
                                            <div style={{ display: "flex", }} >
                                                <Input

                                                    value={puanList?.find(el => el.id == item?.examAnswer?.id)?.point}
                                                    placeholder='Puan' type='number' style={{
                                                        border: "1px solid #1677FF",
                                                        width: "90px"
                                                    }}
                                                    onChange={(e) => {
                                                        let newPoint = Math.min(e.target.value, item.point); // Girilen değeri item.point ile sınırla
                                                        if (newPoint < 0) {
                                                            newPoint = 0
                                                        }
                                                        setPuanList(puanList.map(el => {
                                                            if (el.id == item?.examAnswer?.id) {
                                                                return {
                                                                    ...el,
                                                                    point: newPoint
                                                                }
                                                            }
                                                            return el
                                                        }));
                                                    }}
                                                />
                                            </div>

                                        </ExamGivePointContainer>
                                        <Input.TextArea disabled value={item?.examAnswer?.answer} style={{
                                            resize: "none",
                                            backgroundColor: "white",
                                            color: "black"

                                        }} />
                                        {
                                            item?.imageUrl !== "Dosya Seçilmedi." && (
                                                <ImageButton onClick={() => {
                                                    setSelectedPhotoModal({
                                                        visible: true,
                                                        url: item?.imageUrl
                                                    })
                                                }} >
                                                    <FaImages size={15} style={{ marginRight: "5px" }} />
                                                    Fotoğraf
                                                </ImageButton>
                                            )
                                        }

                                    </StyledGivePointModalQuestionContainer>

                                )

                            })
                        }
                    </StyledGiveQuestionWrapper>

                </StyledModalBody>
                <ModalFooter style={{ marginTop: "10px" }} >
                    <StyledFooter>
                        <p style={{ fontWeight: "bold", backgroundColor: "green", padding: "2px 15px", borderRadius: 5, color: "white" }} >
                            Toplam Puan :
                            <span style={{ marginLeft: 10 }} >
                                {puanList?.reduce((acc, item) => acc += item.point, 0)}
                            </span>
                        </p>
                        <AntButton type='primary' onClick={() => {
                            pointProcess()
                        }} >
                            Kaydet
                        </AntButton>
                    </StyledFooter>

                </ModalFooter>
            </Modal>


            <Modal isOpen={selectedPhotoModal.visible} size='md' >
                <PhotoModalHeader>
                    Fotoğraf
                    <AntButton danger onClick={() => {
                        setSelectedPhotoModal({
                            visible: false,
                            url: ""
                        })
                    }}  >
                        Kapat
                    </AntButton>
                </PhotoModalHeader>
                <ModalBody>
                    <img src={selectedPhotoModal.url} style={{ width: "100%" }} alt="" />
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </>
    )
}

const StyledModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .exam_give_modal_username{
        font-size: 16px;
    }
`

const StyledGiveQuestionWrapper = styled.div`
    flex-direction: column;
    display: flex;
    row-gap: 20px;
`

const StyledGivePointModalQuestionContainer = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
border: 1px solid gray;
border-radius: 10px;
row-gap: 5px;
padding: 20px;
  .question_give_modal_index{
    width: 30px;
    height: 30px;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
  }


`

const ExamGivePointContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    .give_question_item_cevap{
    color: white;
    padding: 0 10px;
    background-color: darkcyan;
    width: max-content;
    border-radius: 5px;
    margin-top: 10px;
  }
  .give_question_item_point{
    background-color: #3577F1;
    padding: 0 15px;
    color: white;
    border-radius: 5px;
  }
`

const StyledModalBody = styled(ModalBody)`
  padding-top: 10px;
  max-height: 75vh;
  overflow-y: scroll;

  /* Firefox */
/*   scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; */

  /* Chrome, Edge, Safari */
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-button {
   display: none;
  }

`
const ImageButton = styled.div`
        position: absolute;
        top: -4%;
        left: 3%;
  background-color: green;
  padding: 0 10px;
  color: white;
  border-radius: 7px;
  cursor: pointer;
`

const PhotoModalHeader = styled(ModalHeader)`

    h5{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export default ExamGivePointModal