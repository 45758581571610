import React, { useEffect, useState } from 'react'
import { examGetAllForStudentApi } from '../../api/exam'
import { BsArrowDown } from 'react-icons/bs'
import DataTable from 'react-data-table-component'
import { Button, Card } from 'reactstrap'
import { formatDate } from 'date-fns'
import trLocale from "date-fns/locale/tr";
import { getMinuteDifference } from '../../utils/utils'
import { FaEye } from 'react-icons/fa'
import StudentExamResultModal from './StudentExamResultModal'
import { Table as AntTable, Input, Button as AntButton } from "antd"

const StudentExamResultDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [examResultData, setExamResultData] = useState([])
    const [modalData, setModalData] = useState({
        visible: false,
        id: ""
    })

    const convertTrFormat = (time) => {
        return formatDate(new Date(time), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
    };
    const getAllForExamProcess = async () => {
        try {
            setLoading(true)
            const responsev1 = await examGetAllForStudentApi({
                page: 0,
                pageSize: 1
            })
            const responsev2 = await examGetAllForStudentApi({
                page: 0,
                pageSize: responsev1.data.count
            })
            console.log("responsev2 =>=> ", responsev2.data)
            const testExams = responsev2.data.items.filter(el => el.type == "Test")
            const yaziliExams = responsev2.data.items.filter(el => el.type == "Yazılı" && el.status == true)
            const responseArr = [...testExams, ...yaziliExams]

            setExamResultData(responseArr)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });




    useEffect(() => {
        getAllForExamProcess()
    }, [])


    return (
        <div>
            <AntTable
                scroll={{
                    x: 800
                }}
                dataSource={examResultData}
                columns={[
                    {
                        title: "İsim",
                        dataIndex: "name",
                        render: (row, record) => <p>{record?.name.length > 20 ? record.name.substring(0, 20) + "..." : record.name}</p>,
                        ...getColumnSearchProps('name'),
                    },
                    {
                        title: "Kurs Türü",
                        dataIndex: "formal",
                        render: (row, record) => <p>{record?.formal}</p>,
                        ...getColumnSearchProps('formal'),
                    },

                    {
                        title: "Kurs",
                        dataIndex: "courseName",
                        render: (row, record) => <p>{record.courseName}</p>,
                        ...getColumnSearchProps('courseName'),
                    },
                    {
                        title: "Başlangıç saati",
                        render: (row, record) => <p>{convertTrFormat(record.startTime)}</p>,
                        sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
                    },
                    {
                        title: "Başlangıç saati",
                        render: (row, record) => <p>{convertTrFormat(record.endTime)}</p>,
                        sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime),
                    },
                    {
                        title: "Sınav süresi",
                        render: (row, record) => <p>{
                            getMinuteDifference(record.startTime, record.endTime)
                        } (Dk) </p>
                    },
                    {
                        title: "Sınav Tipi",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.type}
                                </p>
                            )
                        }
                    },
                    {
                        title: "İşlem",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={() => {

                                    setModalData({
                                        visible: true,
                                        id: record.id,
                                        examName: record.name,
                                        formal: record.formal,
                                        type: record?.type
                                    })
                                }} >
                                    <FaEye />

                                </AntButton>
                            )
                        }
                    }
                ]}

            />

            <StudentExamResultModal modalInfo={modalData} setModalInfo={setModalData} />
        </div>
    )
}

export default StudentExamResultDashboard