import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { axiosInstance } from '../../../../api/axiosInstance'
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { format } from "date-fns"
import trLocale from "date-fns/locale/tr"
import { Table as AntTable, Button as AntButton } from "antd"
import CourseUserAbsencesModal from './CourseUserAbsencesModal'

const CourseUserAbsencesDashboard = () => {
    const { id } = useParams()
    const [mainDatas, setMainDatas] = useState([])

    const [modalData, setModalData] = useState({
        show: false,
        data: [],
        calendarInfo: null
    })


    const getAbcenses = async () => {
        try {
            const groupByList = []
            const response = await axiosInstance.post(`/absences/getallforcourseId?courseId=${id}`, {
                page: 0,
                pageSize: 1000
            })
            response.data.items.forEach(item => {
                let temp = groupByList.findIndex(el => el.courseCalendarId == item.courseCalendarId)
                if (temp == -1) {
                    groupByList.push({
                        courseCalendarId: item.courseCalendarId,
                        courseId: item.courseId,
                        courseName: item.courseName,
                        ...item.courseCalendar,
                        users: [{
                            ...item.user,
                            leftOn: item.leftOn,
                            registeredOn: item.registeredOn,
                            userActiveTime: item.userActiveTime
                        }]
                    })
                }
                else {
                    groupByList[temp]["users"].push({
                        ...item.user,
                        leftOn: item.leftOn,
                        registeredOn: item.registeredOn,
                        userActiveTime: item.userActiveTime
                    })
                }

            })
            setMainDatas(groupByList)
        }
        catch (err) {

        }
    }

    useEffect(() => {
        getAbcenses()
    }, [])
    function getTurkishDay(dateString) {
        const weekdays = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"];
        const date = new Date(dateString);
        const dayIndex = date.getDay();
        return weekdays[dayIndex];
    }



    const modalColumns = [
        {
            name: "İsim",
            selector: (row) => {
                return (
                    <p style={{ textTransform: "capitalize" }} >
                        <span style={{ marginRight: "5px" }}>
                            {row?.firstName}
                        </span>
                        {
                            row.userOperationClaims[0]?.operationClaimId == 2 && (
                                <span>
                                    ( Eğitmen )
                                </span>
                            )
                        }
                    </p>
                )
            }
        },
        {
            name: "Soyisim",
            selector: (row) => {
                return (
                    <span>
                        {row.lastName}
                    </span>
                )
            }
        },
        {
            name: "Aktif Süre",
            selector: (row) => {
                return (
                    <span>
                        {row?.userActiveTime.toFixed(2)} (Dk)
                    </span>
                )
            }
        },
        {
            name: "Ayrılma Saati",
            selector: (row) => {
                return (
                    <span>
                        {new Date(row.leftOn).toLocaleTimeString()}
                    </span>
                )
            }
        }
    ]





    return (
        <>
            <AntTable
                dataSource={mainDatas}
                pagination={{
                    pageSize: 10
                }}
                scroll={{
                    x: 800
                }}
                columns={[
                    {
                        title: "Gün",
                        render: (row, record) => {
                            return (
                                <p>
                                    {getTurkishDay(row.startTime)}
                                </p>
                            )
                        }
                    },
                    {
                        title: "Başlangıç",
                        render: (row, record) => {
                            return (
                                <span> {new Date(record?.startTime).toLocaleString()} </span>
                            )
                        },
                        sorter: (a, b) => {
                            return new Date(a.startTime) - new Date(b.startTime)
                        }

                    },
                    {
                        title: "Bitiş",
                        render: (row, record) => {
                            return (
                                <span> {new Date(record?.endTime).toLocaleString()} </span>
                            )
                        },
                        sorter: (a, b) => {
                            return new Date(a.endTime) - new Date(b.endTime)
                        }
                    },
                    {
                        title: "Kurs Süresi",
                        render: (row, record) => {
                            return (
                                <span > {record.duration} (Dk)  </span>
                            )
                        }
                    },
                    {
                        title: "İncele",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={() => {
                            
                                    const teacher = row.users.find(x => x.userOperationClaims[0]["operationClaimId"] == 2)
                                    const filteredList = row.users.filter(x => x.userOperationClaims[0]["operationClaimId"] !== 2)
                                    const newList = [teacher, ...filteredList]
                                    setModalData({
                                        show: true,
                                        data: newList,
                                        calendarInfo: {
                                            courseName: row.courseName,
                                            startTime: format(new Date(row.startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale }),
                                        }
                                    })
                                }} >
                                    Devam Durumu
                                </AntButton>
                            )
                        }
                    }
                ]}
            />

            {/* <Modal isOpen={modalData.show} size='lg' >
                <ModalHeader>
                    <div style={{ display: "flex", justifyContent: "space-between" }} >
                        <p style={{ fontSize: "14px" }} >
                            {modalData.calendarInfo?.startTime}
                        </p>
                        <p style={{ textTransform: "capitalize", fontSize: "14px" }}>
                            {modalData.calendarInfo?.courseName}
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Input placeholder='Ara' style={{ marginBottom: "10px" }} onChange={(e) => {
                        setSearchText(e.target.value)
                    }} />
                    <AntTable
                        size='middle'
                        dataSource={searchData}
                        pagination={{
                            pageSize: 5
                        }}
                        scroll={{
                            x: 600
                        }}
                        columns={[
                            {
                                title: "İsim",
                                render: (row, record) => {
                                    return (
                                        <p style={{ textTransform: "capitalize" }} >
                                            <span style={{ marginRight: "5px" }}>
                                                {record?.firstName}
                                            </span>
                                            {
                                                record.userOperationClaims[0]?.operationClaimId == 2 && (
                                                    <span>
                                                        ( Eğitmen )
                                                    </span>
                                                )
                                            }
                                        </p>
                                    )
                                }
                            },
                            {
                                render: (row, record) => {

                                }
                            }
                        ]}
                    />
                    <DataTable
                        data={searchData}
                        columns={modalColumns}
                        pagination
                        noDataComponent={
                            <Card className="w-100 p-5">
                                <center>
                                    <h2>Herhangi bir veri bulunamadı.</h2>
                                </center>
                            </Card>
                        }
                        paginationComponentOptions={{
                            rowsPerPageText: "Satır Sayısı",
                            rangeSeparatorText: "-",
                            noRowsPerPage: true
                        }}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button color='danger' onClick={() => {
                        setModalData({
                            show: false,
                            data: [],
                            calendarInfo: null
                        })
                    }} >
                        Kapat
                    </Button>
                </ModalFooter>
            </Modal> */}

            <CourseUserAbsencesModal modalData={modalData} setModalData={setModalData} />
        </>

    )
}

export default CourseUserAbsencesDashboard