// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_social_media_icon {
  font-size: 20px;
  transition-duration: 300ms; }

.footer_social_media_icon:hover {
  color: rgba(0, 0, 0, 0.799);
  scale: 1.05;
  transition-duration: 300ms; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/_footer.scss"],"names":[],"mappings":"AAAA;EACC,eAAe;EACf,0BAA0B,EAAA;;AAG3B;EACC,2BAA2B;EAC3B,WAAW;EACX,0BAA0B,EAAA","sourcesContent":[".footer_social_media_icon {\n font-size: 20px;\n transition-duration: 300ms;\n}\n\n.footer_social_media_icon:hover {\n color: rgba(0, 0, 0, 0.799);\n scale: 1.05;\n transition-duration: 300ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
