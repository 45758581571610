import { Helmet } from "react-helmet-async"
import { Container, Row, Col } from "reactstrap"
import MainFooter from '../../components/mainFooter/MainFooter'
import "./index.scss"
import { useTranslation } from "react-i18next";
import MainNavbar from '../../components/navbar/MainNavbar';
import FontHead from '../../components/fontHead/FontHead';
import i1 from "../../../assets/images/i1.png"
import i2 from "../../../assets/images/i2.png"
import i3 from "../../../assets/images/i3.png"
import i4 from "../../../assets/images/i4.png"
import i5 from "../../../assets/images/i5.png"
import i6 from "../../../assets/images/i6.png"
import EKitapCardComponent from "./EKitapCardComponent";
import { useEffect } from "react";
import styled from "styled-components";
import TopBgElement from "../TopBgElement/TopBgElement";
import ekitapbg from "../../../assets/images/ekitapbg.png"
import { Alert } from "antd";
import OtherCourseSlider from "../../components/OtherCourseSlider/OtherCourseSlider";

const Ekitap = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0)
  }, [])



  return (
    <StyledContainer>

      <Helmet>
        <meta name="description" content="KUZEM E-kitap arşivimize göz atın. Anasayfa / E-Kitap sayfamız güncellenmektedir." />
        <meta name="keywords" content="E-kitap, Kitap, Arşiv, Eğitim" />
        <meta property="og:description" content="KUZEM E-kitap arşivimize göz atın. Anasayfa / E-Kitap sayfamız güncellenmektedir." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.kuzem.org/ekitap" />
        <meta property="og:image" content={ekitapbg} />
        
      </Helmet>
      <MainNavbar />
      <TopBgElement img={ekitapbg} mainTitle={"E-Kitap"} subTitle={"Anasayfa / E-Kitap"} />

      <EKitapCardContainer >
        <MainContentContainer>
          <Alert type="info" message="Bu alan güncellenmektedir" />
          <OtherCourseWrapper>

            <OtherCourseSlider />
          </OtherCourseWrapper>

        </MainContentContainer>
      </EKitapCardContainer>
      <MainFooter visibility={"visible"} />
    </StyledContainer>


  )
}

export default Ekitap

const StyledContainer = styled.div`
    width: 100%;
    flex-direction: column;
`

const EKitapCardContainer = styled.div`
    padding: 40px 120px;
    margin: 40px 0 80px 0;
    row-gap: 50px;
    column-gap: 30px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin: 50px 0;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
        grid-template-columns: 1fr ;
    }
`

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`

const OtherCourseWrapper = styled.div`
  display: flex;
  flex-direction: column;

`