import {
 XOutlined,
 InstagramOutlined,
 YoutubeOutlined,
} from "@ant-design/icons";
import React from "react";
import "../assets/scss/components/_footer.scss";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
 return (
  <React.Fragment>
   <footer className="footer">
    <Container fluid>
     <Row className="pb-3 px-2">
      <Col sm={6}>
       <div className="text-sm-start d-none d-sm-block ">
        2024 © KUZEM <br /> Konya Büyükşehir Belediyesi - Şehir Teknolojileri
        Merkezi
       </div>
      </Col>
      <Col
       sm={6}
       style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        gap: "5px",
       }}
      >
       <a href="https://www.instagram.com/komekuzem/" target="_blank">
        <InstagramOutlined className="footer_social_media_icon" />
       </a>
       <a href="https://x.com/KomekUzem" target="_blank">
        <XOutlined className="footer_social_media_icon" />
       </a>
       <a href="https://www.youtube.com/@komekuzem" target="_blank">
        <YoutubeOutlined className="footer_social_media_icon" />
       </a>
      </Col>
     </Row>
    </Container>
   </footer>
  </React.Fragment>
 );
};

export default Footer;

const IconStyle = {};
