import React, { useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../Common/TablePageContainer'
import { Button, Select, Switch, Table, Modal as AntModal, Input, Alert, Row, Col } from "antd"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { FaEye } from 'react-icons/fa'
import { getAllBranch } from '../../api/Branch'
import EgitmenSoruGuncelle from '../EgitmenSoruHavuzu/EgitmenSoruGuncelle'
import { getUserListApi } from '../../api/UserApi'


const SoruHavuzu = () => {
    const [branchList, setBranchList] = useState([])
    const [activeStatusType, setActiveStatusType] = useState("Bekliyor")
    const [selectedBranch, setSelectedBranch] = useState("Hepsi")
    const [questionList, setQuestionList] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [teacherList, setTeacherList] = useState([])
    const [selectedTeacher, setSelectedTeacher] = useState("Hepsi")
    const [editMode, setEditMode] = useState({
        visible: false
    })
    const [selectedLevel, setSelectedLevel] = useState("Hepsi")
    const [answerModal, setAnswerModal] = useState({
        visible: false,
        label: "",
        data: []
    })

    const getQuestions = async (statusType = 0, pageIndex = 0) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/BranchQuestions/GetAll", {
                branchQuestionStatus: statusType,
                pageRequest: {
                    page: pageIndex,
                    pageSize: 10
                }
            })

            console.log("rexx", response.data)

            setQuestionList(response.data.items.map(el => {
                const { branch, ...rest } = el

                return {
                    ...rest,
                    branch: branch.name
                }
            }))

            setTotalCount(response.data.count)
        }
        catch (err) {


        }
        finally {
            setLoading(false)
        }
    }

    const getAllBranchList = async () => {
        try {
            const response = await getAllBranch({
                page: 0,
                pageSize: 1000
            })
            setBranchList(response.data.items)

        }
        catch (err) {

        }
    }

    const updateStatus = async (id, status) => {
        try {
            setLoading(true)
            const response = await axiosInstance.put("/BranchQuestions/UpdateStatus", {
                id: id,
                branchQuestionStatus: status
            })
            toast.success("Güncellendi", {
                autoClose: 2000,
                position: "top-center"
            })
            getQuestions(activeStatusType)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value, record) => {

            return record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : ''
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    const mainData = useMemo(() => {
        return questionList
    }, [questionList, searchText, searchedColumn])


    const getTeacherList = async () => {
        try {
            const response = await getUserListApi({
                page: 0,
                pageSize: 10,
                roleId: 2
            })
            const responsev2 = await getUserListApi({
                page: 0,
                pageSize: response.data.count,
                roleId: 2
            })
            console.log("responvx", responsev2.data)
            setTeacherList(response.data.items)
        }
        catch (err) {

        }
        finally {

        }
    }


    const getQuestinDynamic = async (status = "Bekliyor", branchId, pageIndex = 0, selectedTeacher = "Hepsi", level = "Hepsi") => {
        try {
            setLoading(true)
            const baseObj = {
                "dynamic": {
                    "sort": [
                        {
                            "field": "id",
                            "dir": "asc"
                        }
                    ],
                    "filter": {
                        "field": "Status",
                        "operator": "eq",
                        "value": status,
                        "logic": "and",
                        "filters": [

                        ]
                    }
                },
                "pageRequest": {
                    "page": pageIndex,
                    "pageSize": 10
                }
            }
            if (branchId != "Hepsi") {
                baseObj.dynamic.filter.filters.push({
                    "field": "branchId",
                    "operator": "eq",
                    "value": branchId,
                    "logic": "and"
                })
            }
            if (selectedTeacher != "Hepsi") {
                baseObj.dynamic.filter.filters.push({
                    "field": "userId",
                    "operator": "eq",
                    "value": selectedTeacher,
                    "logic": "and"
                })
            }
            if (level != "Hepsi") {
                baseObj.dynamic.filter.filters.push({
                    "field": "level",
                    "operator": "eq",
                    "value": level,
                    "logic": "and"
                })
            }

            const response = await axiosInstance.post("/BranchQuestions/GetAllByDynamic", baseObj)
            setTotalCount(response.data.count)
            setQuestionList(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }



    useEffect(() => {
        /*  getQuestions() */
        getQuestinDynamic("Bekliyor", "Hepsi", 0, "Hepsi", "Hepsi")
        getAllBranchList()
        getTeacherList()
    }, [])

    return (
        <div className='' >
            <StyledHeader>
                <Row style={{
                    width: "100%"
                }}
                    gutter={[
                        {
                            sm: 10
                        }, {
                            xs: 10
                        }
                    ]}
                >
                    <Col md={6} sm={24} xs={24} >
                        <StyledSelect
                            value={activeStatusType}
                            onChange={(e) => {
                                setActiveStatusType(e)
                                /* getQuestions(e) */
                                getQuestinDynamic(e, selectedBranch, 0, selectedTeacher, selectedLevel)
                                setCurrentPage(1)
                            }}
                            placeholder="Durum"
                            options={[
                                {
                                    "label": "Bekleyen",
                                    "value": "Bekliyor"
                                },
                                {
                                    "label": "Reddedilen",
                                    "value": "Reddedildi"
                                },
                                {
                                    "label": "Onaylanan",
                                    "value": "Onaylandı"
                                }
                            ]}
                        />
                    </Col>
                    <Col md={6} sm={24} xs={24} >
                        <StyledSelect
                            value={selectedBranch}
                            placeholder="Branş Seçiniz"
                            onChange={e => {
                                setSelectedBranch(e)
                                getQuestinDynamic(activeStatusType, e, 0, selectedTeacher, selectedLevel)
                                setCurrentPage(1)
                            }}
                            showSearch
                            filterOption={(input, option) => {

                                return (
                                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                                )
                            }}
                            options={[
                                {
                                    label: "Hepsi",
                                    value: "Hepsi"
                                },
                                ...branchList?.map(item => {
                                    return {
                                        "label": item?.branch?.name ?? item.name,
                                        "value": item?.branch?.id ?? item.id
                                    }
                                })
                            ]}

                        />
                    </Col>
                    <Col md={6} sm={24} xs={24} >
                        <StyledSelect
                            value={selectedTeacher}
                            placeholder="Eğitmen Seçiniz"
                            onChange={e => {
                                setSelectedTeacher(e)
                                getQuestinDynamic(activeStatusType, selectedBranch, 0, e, selectedLevel)
                                setCurrentPage(1)
                            }}
                            showSearch
                            filterOption={(input, option) => {

                                return (
                                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                                )
                            }}
                            options={[
                                {
                                    label: "Hepsi",
                                    value: "Hepsi"
                                },
                                ...teacherList?.map(item => {
                                    return {
                                        "label": `${item?.firstName} ${item?.lastName}`,
                                        "value": item.id
                                    }
                                })
                            ]}

                        />
                    </Col>
                    <Col md={6} sm={24} xs={24} >
                        <StyledSelect
                            value={selectedLevel}
                            placeholder="Zorluk Derecesi"
                            onChange={e => {
                                setSelectedLevel(e)
                                getQuestinDynamic(activeStatusType, selectedBranch, 0, selectedTeacher, e)
                                setCurrentPage(1)
                            }}
                            showSearch
                            filterOption={(input, option) => {

                                return (
                                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                                )
                            }}
                            options={[
                                {
                                    label: "Hepsi",
                                    value: "Hepsi"
                                },
                                {
                                    label: "Kolay",
                                    value: "Kolay"
                                },
                                {
                                    label: "Orta",
                                    value: "Orta"
                                },
                                {
                                    label: "Zor",
                                    value: "Zor"
                                }
                            ]}

                        />
                    </Col>
                </Row>

            </StyledHeader>
            <Table
                size='middle'
                loading={loading}
                dataSource={mainData}
                scroll={{
                    x: 800
                }}
                pagination={{
                    pageSize: 10,
                    total: totalCount,
                    current: currentPage,
                    showSizeChanger: false,
                    onChange: (e) => {

                        setCurrentPage(e)
                        getQuestinDynamic(activeStatusType, selectedBranch, e - 1, selectedTeacher, selectedLevel)

                    }
                }}
                columns={[
                    {
                        "title": "Soru",

                        render: (row, record) => {
                            return (
                                <span>
                                    {record?.question?.length > 50 ? `${record?.question?.substring(0, 50)}...` : record?.question}
                                </span>
                            )
                        },


                    },
                    {
                        "title": "Toplam Kullanım",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.totalUsage}
                                </p>
                            )
                        }
                    },
                    {
                        title: "Zorluk",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.level == "0" ? "Seçilmedi" : record?.level}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Puan",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.point}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Branş",
                        dataIndex: "branch",

                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.branch?.name}
                                </p>
                            )
                        },

                        /* ...getColumnSearchProps("branch") */

                    },
                    {
                        "title": "Ekleyen",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.user?.firstName} {record?.user?.lastName}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Durum",
                        render: (row, record) => {
                            return (
                                <p style={{ padding: "5px 8px", backgroundColor: "orange", borderRadius: "5px", width: "max-content", color: "white" }}  >
                                    {record.status}
                                </p>
                            )
                        }
                    },
                    {
                        "title": "Cevap Şıkları",
                        render: (row, record) => {
                            return (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }} >
                                    <Button type='primary' onClick={() => {

                                        /* setAnswerModal({
                                            visible: true,
                                            data: record?.branchQuestionAnswers,
                                            label: record?.question
                                        }) */

                                        setEditMode({
                                            visible: true,
                                            data: record
                                        })
                                    }} >
                                        <FaEye />
                                    </Button>
                                </div>
                            )
                        }
                    },
                    {
                        "title": "İşlem Yap",
                        render: (row, record) => {
                            return (
                                <div style={{ display: "flex", columnGap: "10px" }}>
                                    <Button type='primary' onClick={() => {
                                        AntModal.confirm({
                                            title: "Aktif etmek istediğinizde emin misiniz ?",
                                            cancelText: "İptal",
                                            okText: "Eminim ",
                                            onOk: () => {
                                                updateStatus(row.id, 2)
                                            }
                                        })
                                    }}  >
                                        Onayla
                                    </Button>
                                    <Button danger type='primary' onClick={() => {
                                        AntModal.confirm({
                                            title: "Reddetmek  istediğinizde emin misiniz ?",
                                            cancelText: "İptal",
                                            okText: "Eminim ",
                                            onOk: () => {
                                                updateStatus(row.id, 1)
                                            }
                                        })
                                    }}>
                                        Reddet
                                    </Button>
                                </div>
                            )
                        }
                    }
                ]}
            />
            <EgitmenSoruGuncelle refreshData={() => {
                getQuestions(activeStatusType)
                getAllBranchList()

            }} editMode={editMode} setEditMode={setEditMode} branchList={branchList} />
            <Modal isOpen={answerModal.visible} size='lg'  >
                <StyledModalHeader>
                    {answerModal.label}
                </StyledModalHeader>
                <ModalBody>
                    <StyledAnswerContainer>
                        {
                            answerModal.data?.length !== 0 ? answerModal.data?.map(item => {
                                return (
                                    <>
                                        <StyledAnswerInputItem disabled={true} correct={item.status} value={item.answer} style={{ resize: "none" }} />
                                    </>
                                )
                            }) : (
                                <Alert type='warning' message="Bu soru için cevap şıkkı eklenmemiş" style={{ width: "100%" }} />
                            )
                        }

                    </StyledAnswerContainer>

                </ModalBody>
                <ModalFooter>

                    <Button type='primary' danger onClick={() => {
                        setAnswerModal({
                            visible: false,
                            data: [],
                            label: ""
                        })
                    }}  >
                        İptal
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default SoruHavuzu


const StyledSelect = styled(Select)`
   width: 100%;
`
const StyledHeader = styled.header`
    display: flex;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed gray;
    justify-content: flex-start;
  
`

const StyledAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
`

const StyledAnswerItem = styled.div`
    display: flex;
    border:  1px solid gray;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
`

const StyledModalHeader = styled(ModalHeader)`

    width: 100%;
    h5 {
        width: 100%;
        text-transform: capitalize;
      /*   border: 1px solid gray;
        border-radius: 10px;
        padding: 10px; */
    }
`

const StyledAnswerInputItem = styled(Input.TextArea)`

    background-color: ${props => props.correct ? "green" : "white"} !important;
    color: ${props => props.correct ? "white" : "black"} !important ;
    font-weight: bold !important;
`