import { lazy } from "react";
import { Navigate } from "react-router-dom";
const Login = lazy(() => import("../pages/Authentication/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const AddTeacherPage = lazy(() => import("../pages/Teacher/AddTeacherPage"));
const EditTeacherPage = lazy(() => import("../pages/Teacher/EditTeacherPage"));
const TeacherDashboard = lazy(() =>
 import("../pages/Teacher/TeacherDashboard")
);
const UpdateAdminPage = lazy(() => import("../pages/Admin/UpdateAdminPage"));
const DocumentVerification = lazy(() =>
 import("../Public/pages/document-verification/DocumentVerification")
);
const BranchDashboard = lazy(() => import("../pages/Branch/BranchDashboard"));
const StudentDasboard = lazy(() => import("../pages/Student/StudentDasboard"));
const UpdateStudentPage = lazy(() =>
 import("../pages/Student/UpdateStudentPage")
);
const ActiveCourseDashboard = lazy(() =>
 import("../pages/Course/ActiveCourseDashboard")
);
const CourseDetailPage = lazy(() => import("../pages/Course/CourseDetailPage"));
const CreateOnlineCoursePage = lazy(() =>
 import("../pages/Course/CreateOnlineCoursePage")
);
const CourseUpdatePage = lazy(() => import("../pages/Course/CourseUpdatePage"));
const TeacherCoursePage = lazy(() =>
 import("../pages/Course/TeacherCoursePage")
);
const ProfilePage = lazy(() => import("../pages/Profile/ProfilePage"));
const Home = lazy(() => import("../Public/Home"));
const Kurumsal = lazy(() => import("../Public/pages/kurumsal/Kurumsal"));
const Ekitap = lazy(() => import("../Public/pages/e-kitap/EKitap"));
const Egitimler = lazy(() => import("../Public/pages/egitimler/Egitimler"));
const Guncel = lazy(() => import("../Public/pages/guncel/Guncel"));
const Blog = lazy(() => import("../Public/pages/blog/Blog"));
const Iletisim = lazy(() => import("../Public/pages/iletisim/İletisim"));
const Kvk = lazy(() => import("../Public/pages/iletisim/Kvk"));
const BlogDetay = lazy(() => import("../Public/pages/blogDetay/BlogDetay"));
const GuncelDetay = lazy(() =>
 import("../Public/pages/GuncelDetay/GuncelDetay")
);
const StudentCourseDashboard = lazy(() =>
 import("../pages/Course/StudentCourseDashboard")
);
const CategoryPage = lazy(() => import("../pages/Categories/CategoryPage"));
const TeacherExamPage = lazy(() =>
 import("../pages/TeacherExam/TeacherExamPage")
);
const OfflineCoursePage = lazy(() =>
 import("../pages/Course/OfflineCoursePage")
);
const CreateOfflineCoursePage = lazy(() =>
 import("../pages/Course/CreateOfflineCoursePage")
);
const TeacherMessagePage = lazy(() =>
 import("../pages/Teacher/TeacherMessagePage")
);
const TeacherSurveyPage = lazy(() =>
 import("../pages/Teacher/TeacherSurveyPage")
);
const StudentSurveyPage = lazy(() =>
 import("../pages/Student/StudenSurveyPage/StudentSurveyPage")
);
const StudentMessagePage = lazy(() =>
 import("../pages/Student/StudentMessagePage/StudentMessagePage")
);
const StudentExamPage = lazy(() =>
 import("../pages/Student/StudentExam/StudentExamPage")
);
const ExamDashboard = lazy(() =>
 import("../pages/Exam/ExamDashboard/ExamDashboard")
);
const ExamManagement = lazy(() =>
 import("../pages/ExamManagement/ExamManagement")
);
const ExamUpdatePage = lazy(() => import("../pages/ExamUpdate/ExamUpdatePage"));
const ExamStudenResultPage = lazy(() =>
 import("../pages/Exam/ExamStudenResult/ExamStudenResultPage")
);
const CertificateStudentTab = lazy(() =>
 import("../Components/Student/EditStudent/CertificateStudentTab")
);
const TeacherOnlineMeetingPage = lazy(() =>
 import("../pages/TeacherOnlineMeeting/TeacherOnlineMeetingPage")
);
const TeacherOnlineMeetingWorksPage = lazy(() =>
 import("../pages/TeacherOnlineMeeting/TeacherOnlineMeetingWorksPage")
);
const StudentOnlineMeeting = lazy(() =>
 import("../pages/StudentOnlineMeeting/StudentOnlineMeeting")
);
const VideoPlayerPage = lazy(() =>
 import("../Public/pages/VideoPage3/VideoPlayerPage/VideoPlayerPage")
);
const Register = lazy(() =>
 import("../pages/Authentication/Register/Register")
);
const EgitmenSoruHavuzu = lazy(() =>
 import("../Components/EgitmenSoruHavuzu/EgitmenSoruHavuzu")
);
const SoruHavuzuAdminPage = lazy(() =>
 import("../pages/SoruHavuzu/SoruHavuzuAdminPage")
);
const BlogPage = lazy(() => import("../pages/Blog/BlogPage"));
const AnnouncementPage = lazy(() =>
 import("../pages/Announcement/AnnouncementPage")
);
const TopicalPage = lazy(() => import("../pages/Topical/TopicalPage"));
const EKitapPage = lazy(() => import("../pages/EKitap/EKitapPage"));
const CourseVideoDashboard = lazy(() =>
 import("../Public/pages/CourseVideo/CourseVideoDashboard")
);
const RecommendedCoursePage = lazy(() =>
 import("../pages/OnerilenKurslar/RecommendedCoursePage")
);
const CevrimiciDetay = lazy(() =>
 import("../pages/CevrimciDetay/CevrimiciDetay")
);
const VideoEgitimDetay = lazy(() =>
 import("../pages/VideoEgitimDetay/VideoEgitimDetay")
);
const Egitmenler = lazy(() => import("../Public/pages/Egitmenler/Egitmenler"));
const EgitmenPage = lazy(() => import("../Public/pages/Egitmen/EgitmenPage"));
const ResetPassword = lazy(() =>
 import("../pages/Authentication/ResetPassword/ResetPassword")
);
const UzaktanEgitim = lazy(() =>
 import("../Public/pages/UzaktanEgitim/UzaktanEgitim")
);
const OgrencilerimPage = lazy(() =>
 import("../pages/Ogrencilerim/OgrencilerimPage")
);
const AydinlatmaMetni = lazy(() =>
 import("../Public/pages/AydinlatmaMetni/AydinlatmaMetni")
);
const AdminStatisticPage = lazy(() =>
 import("../pages/AdminStaticticPage/AdminStatisticPage")
);
const AddBranchPage = lazy(() =>
 import("../pages/AddBranchPage/AddBranchPage")
);
const EditBranchPage = lazy(() =>
 import("../pages/EditBranchPage/EditBranchPage")
);
const SiteHaritasi = lazy(() =>
 import("../Public/pages/SiteHaritasi/SiteHaritasi")
);
const SmsPage = lazy(() => import("../pages/SmsPage/SmsPage"));
const MeetingPage = lazy(() => import("../pages/MeetingPage/MeetingPage"));
const OgrenciDevamsızlıkPage = lazy(() =>
 import("../pages/OgrenciDevamsızlıkPage/OgrenciDevamsızlıkPage")
);
const Mail = lazy(() => import("../pages/Mail/Mail"));
const Drive = lazy(() => import("../pages/Drive/Drive"));

const authProtectedRoutes = [
 {
  path: "/panel/anasayfa",
  component: <Dashboard />,
  role: ["Öğretmen", "Öğrenci", "Admin", "SüperAdmin"],
 },
 {
  path: "/panel/egitmen",
  component: <TeacherDashboard />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/egitmen/ekle",
  component: <AddTeacherPage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/egitmen/duzenle/:id",
  component: <EditTeacherPage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/admin/duzenle/:id",
  component: <UpdateAdminPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/branslar",
  component: <BranchDashboard />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/brans/ekle",
  component: <AddBranchPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/brans/duzenle/:id",
  component: <EditBranchPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/kullanıcılar",
  component: <StudentDasboard />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/kategori",
  component: <CategoryPage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/ogrenci/duzenle/:id",
  component: <UpdateStudentPage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/cevrimici",
  component: <ActiveCourseDashboard />,
  role: ["Admin", "SüperAdmin", "Öğrenci"],
 },
 {
  path: "/panel/cevrimdisi",
  component: <OfflineCoursePage />,
  role: ["Admin", "SüperAdmin", "Öğrenci"],
 },
 {
  path: "/panel/kurs/:id",
  component: <CourseDetailPage />,
  role: ["Admin", "SüperAdmin", "Öğrenci"],
 },
 {
  path: "/panel/cevrimici/ekle",
  component: <CreateOnlineCoursePage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/cevrimdisi/ekle",
  component: <CreateOfflineCoursePage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/kurs/duzenle/:id",
  component: <CourseUpdatePage />,
  role: ["Admin", "SüperAdmin"],
 },
 {
  path: "/panel/sorumlukurslar",
  component: <TeacherCoursePage />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/kurslarim",
  component: <StudentCourseDashboard />,
  role: ["Öğrenci"],
 },
 {
  path: "/panel/profil",
  component: <ProfilePage />,
  role: ["Öğretmen", "Öğrenci", "Admin", "SüperAdmin"],
 },
 {
  path: "/panel/egitmen/sinav",
  component: <TeacherExamPage />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/egitmen/mesaj",
  component: <TeacherMessagePage />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/ogrenci/mesaj",
  component: <StudentMessagePage />,
  role: ["Öğrenci"],
 },
 {
  path: "/panel/anket",
  component: <TeacherSurveyPage />,
  role: ["Öğretmen", "Admin", "SüperAdmin"],
 },
 {
  path: "/panel/ogrenci/anket",
  component: <StudentSurveyPage />,
  role: ["Öğrenci"],
 },
 {
  path: "/panel/sinavlarim",
  component: <StudentExamPage />,
  role: ["Öğrenci"],
 },
 {
  path: "panel/sinav/:id",
  component: <ExamDashboard />,
  role: ["Öğrenci"],
  template: false,
 },
 {
  path: "/panel/sinavlar",
  component: <ExamManagement />,
  role: ["Öğretmen", "SüperAdmin"],
 },
 {
  path: "/panel/sinav/sonuclarim",
  component: <ExamStudenResultPage />,
  role: ["Öğrenci"],
 },
 {
  path: "/panel/sinav/duzenle/:id",
  component: <ExamUpdatePage />,
  role: ["Öğretmen", "SüperAdmin"],
 },
 {
  path: "/panel/sertifika",
  component: <CertificateStudentTab />,
  role: ["Öğrenci"],
 },
 {
  path: "/panel/canliderslerim",
  component: <TeacherOnlineMeetingPage />,
  role: ["Öğretmen", "Öğrenci"],
 },
 {
  path: "/panel/egitmen/canliders",
  component: <TeacherOnlineMeetingPage />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/egitmen/canliders/odevler/:courseWorkId",
  component: <TeacherOnlineMeetingWorksPage />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/ogrenci/canliders",
  component: <StudentOnlineMeeting />,
  role: ["Öğrenci"],
 },
 {
  path: "/panel/soruhavuzu",
  component: <SoruHavuzuAdminPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/egitmen/soruhavuzu",
  component: <EgitmenSoruHavuzu />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/blog",
  component: <BlogPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/duyurular",
  component: <AnnouncementPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/guncel",
  component: <TopicalPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/ekitap",
  component: <EKitapPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/onerilenkurslar",
  component: <RecommendedCoursePage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/cevrimicidetay",
  component: <CevrimiciDetay />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/videoegitimdetay",
  component: <VideoEgitimDetay />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/egitmen/ogrencilerim",
  component: <OgrencilerimPage />,
  role: ["Öğretmen"],
 },
 {
  path: "/panel/istatistik",
  component: <AdminStatisticPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/sms/ogrenci",
  component: <SmsPage />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/toplanti",
  component: <MeetingPage />,
  role: ["SüperAdmin", "Öğretmen"],
 },
 {
  path: "/panel/mail",
  component: <Mail />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/drive",
  component: <Drive />,
  role: ["SüperAdmin"],
 },
 {
  path: "/panel/devamsizlik",
  component: <OgrenciDevamsızlıkPage />,
  role: ["Öğrenci"],
 },
];

const publicRoutes = [
 // Authentication Page
 { path: "/", component: <Home /> },
 { path: "/kurumsal", component: <Kurumsal /> },
 { path: "/ekitap", component: <Ekitap /> },
 { path: "/egitimler/:id", component: <Egitimler /> },
 { path: "/egitmenler", component: <Egitmenler /> },
 { path: "/giris", component: <Login /> },
 {
  path: "/kayit",
  component: <Register />,
 },
 {
  path: "/sifremiunuttum",
  component: <ResetPassword />,
 },
 { path: "/guncel", component: <Guncel /> },
 { path: "/guncel/:id", component: <GuncelDetay /> },
 { path: "/blog", component: <Blog /> },
 { path: "/blog/:id", component: <BlogDetay /> },
 { path: "/blogdetay", component: <BlogDetay /> },
 { path: "/iletisim", component: <Iletisim /> },
 { path: "/kvk", component: <Kvk /> },
 { path: "/kurs/:id", component: <CourseVideoDashboard /> },
 {
  path: "/aydinlatmametni",
  component: <AydinlatmaMetni />,
 },
 {
  path: "/uzaktanegitim",
  component: <UzaktanEgitim />,
 },
 {
  path: "/kurs/player/:id",
  component: <VideoPlayerPage />,
 },
 {
  path: "/egitmen/:id",
  component: <EgitmenPage />,
 },
 {
  path: "/siteharitasi",
  component: <SiteHaritasi />,
 },
 {
  path: "/belge-dogrulama",
  component: <DocumentVerification />,
 },
 {
  path: "*",
  component: <Navigate to={"/"} />,
  role: ["Öğretmen", "Öğrenci", "Admin", "SüperAdmin"],
 },
];

export { publicRoutes, authProtectedRoutes };
